<template>
    <div class="addDimension">
        <!-- 新增维度、编辑维度 -->
        <el-dialog :visible.sync="dialogVisible" width="620px" title="新增维度" :show-close="false">
            <div class="content">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" size="small">
                    <el-form-item prop="name">
                        <span>维度名称</span>
                        <br />
                        <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span>已选题目</span> <span>{{
                                problemIdList.length
                        }}</span>
                        <br />
                        <div v-if="problemOrderNumList.length > 0" class="titleContent">
                            <p>
                                <span v-for="(ele, i) in problemOrderNumList" :key="ele">{{ ele }}
                                <span v-if="i !== problemOrderNumList.length - 1">,</span>
                            </span>
                            </p>
                        </div>
                        <div v-else class="titleContent">暂未选择</div>
                    </el-form-item>
                    <el-form-item prop="formula">
                        <span>计分公式</span> <span>(请输入英文字符)</span>
                        <br />
                        <el-input type="textarea" autosize placeholder="请输入计分公式" v-model="ruleForm.formula">
                        </el-input>
                        <!-- <el-input v-model="ruleForm.formula"></el-input> -->
                    </el-form-item>
                    <el-form-item>
                        <span>题目选择</span>
                        <div class="table">
                            <el-table :data="tableData" border style="width: 100%" height="310"
                                @selection-change="handleSelectionChange">
                                <el-table-column type="selection"></el-table-column>
                                <el-table-column prop="problem.orderNum" label="题目编号" width="100">
                                </el-table-column>
                                <el-table-column prop="problem.name" label="题目">
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-form-item>
                    <!-- <el-form-item>
                        <div class="btn">
                            <el-button @click="cancel">取消</el-button>
                            <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>

                        </div>
                    </el-form-item> -->
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')" size="small">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { getTitleList } from '@/api/gauge/titleEdit.js'
import { addDimension } from '@/api/gauge/dimension'
export default {
    data() {
        return {
            dialogVisible: false,
            dimensionId: 0, // 当前维度的ID
            ruleForm: {
                gaugeId: 0,
                formula: '',
                name: '',
                problemIdList: [
                ]
            },
            rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { max: 15, message: '长度在 15 个字符内', trigger: 'change' }
                ],
                formula: [
                    { required: true, message: '请输入计分公式', trigger: 'blur' }
                ]
            },
            tableData: [],
            problemIdList: [], // 保存用户选择的题的id
            problemOrderNumList: [], // 所选题的序号
            formula: null
        }
    },
    methods: {
        // 控制模态框的开关
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        // 打开新增模态框
        openAdd() {
            //避免缓存 打开之前先将之前的信息清空
            this.ruleForm = {
                gaugeId: 0,
                formula: '',
                name: '',
                problemIdList: [
                ]
            };
            this.getTitle()
            this.dialogVisible = true;
        },
        // 获取所有题目
        async getTitle() {
            const res = await getTitleList({ id: sessionStorage.getItem('gaugeId') })
            console.log(res)
            if (res.code === 200) {
                this.tableData = res.data;
            }
        },
        // 提交
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    console.log(this.ruleForm);
                    this.ruleForm.gaugeId = sessionStorage.getItem('gaugeId');
                    this.ruleForm.problemIdList = this.problemIdList;
                    // console.log(this.ruleForm);
                    this.ruleForm.newFormula = this.formula;
                    console.log(this.ruleForm)
                    const res = await addDimension(JSON.stringify(this.ruleForm))
                    console.log(res);
                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            message: '新增成功',
                            type: 'success'
                        });
                        this.$emit('getList')
                    } else {
                        this.$message({
                            showClose: true,
                            message: '新增失败' + res.msg,
                            type: 'error'
                        });
                    }
                    this.dialogVisible = false;
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 取消
        cancel() {
            this.dialogVisible = false;
        },
        // 点击选择题
        handleSelectionChange(val) {
            // console.log(val)
            this.problemIdList = [];
            this.problemOrderNumList = [];
            val.filter(item => {
                this.problemIdList.push(item.problem.id);
                this.problemOrderNumList.push(item.problem.orderNum);
            })
            // console.log(this.problemOrderNumList)
            // let list = this.problemOrderNumList.map(item => {
            //     console.log(item)
            //     return item
            // })
            // 自动生成计分公式
             // 自动生成计分公式
             this.ruleForm.formula = this.problemOrderNumList.join('题+');
            this.ruleForm.formula =this.ruleForm.formula + '题';
            // this.ruleForm.formula = this.problemOrderNumList.join('+');
            // // this.ruleForm.formula =this.ruleForm.formula + '题'
            // this.formula = this.problemOrderNumList.join('题+');
            // this.formula =this.formula + '题'
            // this.ruleForm.formula = this.problemOrderNumList.join('题+');
            // console.log(this.problemOrderNumList.join('+'));
            // this.ruleForm.formula = this.problemOrderNumList
            // console.log(this.problemIdList);
        },
        toggleSelection(rows) {
            rows.forEach(row => {
                this.$refs.multipleTable.toggleRowSelection(row);
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.addDimension {

    // 设置表格的高度
    ::v-deep.el-table .el-table__cell {
        height: 32px !important;
        padding: 0px !important;
        line-height: 32px !important;
    }

    //    设置输入框的样式
    // .el-input{
    //     height: 30px !important;
    // }
    // ::v-deep.el-input__inner{
    //     height: 30px !important;
    //     line-height: 30px !important;
    // }
    // ::v-deep.el-form-item__content{
    //     line-height: 30px !important;
    // }
    // // 设置模态框的样式
    // ::v-deep.el-dialog__header {
    //     text-align: center;
    // }
    ::v-deep.el-dialog {
        margin-top: 10vh !important;
        margin-bottom: 0px !important;
        height: 75%;
        overflow-y: scroll;
        // position: relative;
    }

    // 设置模态框的样式
    ::v-deep.el-dialog__header {
        text-align: center;
        background-color: white;
        z-index: 2;
        position: sticky;
        top: 0;
    }

    ::v-deep.el-dialog__footer {
        text-align: center;
        background-color: white;
        z-index: 2;
        position: sticky;
        bottom: 0px;

        .el-button {
            margin: 0 30px;
        }
    }

    ::v-deep.el-dialog__body {
        padding: 0 !important;
    }

    ::v-deep.el-dialog__body {
        padding: 0 !important;

        .content {
            padding: 0 40px;

            .el-form-item {
                margin-bottom: 10px;
            }

            .btn {
                display: flex;
                justify-content: center;
            }

            .titleContent {
                box-sizing: border-box;
                width: 100%;
                // height: 32px;
                border: 1px solid #dddddd;
                font-size: 13px;
                color: #999;
                background: #f3f2f2;
                border-radius: 5px;
                padding-left: 20px;
                // line-height: 32px;
                p{
                    line-height: 18px;
                }
            }

        }
    }
}
</style>