// 题目编辑
import request from '@/utils/request'
// 获取量表列表
export function getTitleList(params) {
  return request({
    url: '/gauge/problem',
    method: 'get',
    params
  });
}
// 修改、新增题目
export function addTitle(data) {
  return request({
    url: '/gauge/problem',
    method: 'post',
    data
  });
}
// 删除题目
export function deleteTitle(params) {
  return request({
    url: '/gauge/problem',
    method: 'delete',
    params
  });
}
// 删除答案选项
export function deleteTitleAnswer(params) {
  return request({
    url: '/gauge/answer',
    method: 'delete',
    params
  });
}
// 修改问题序号
export function updateTitleOrderNum(data) {
  return request({
    url: '/gauge/problem/ordernum',
    method: 'post',
    data
  });
}
// 修改答案序号
export function updateAnswerOrderNum(data) {
  return request({
    url: '/gauge/answer/ordernum',
    method: 'post',
    data
  });
}