<template>
    <div>
        <!-- 新增人员 -->
        <el-dialog :title="name" :visible.sync="dialogVisible" width="700px" :show-close="false" append-to-body>
            <template>
                <el-transfer v-model="value" :titles="['未选人员', '已选人员']" :data="data" :props="{
                    key: 'id',
                    label: 'nickname'
                }" filterable>
                </el-transfer>
            </template>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getNotMember, updateOrganizationMemberRole } from '@/api/system/organization'
export default {

    data() {
        return {
            dialogVisible: false,
            data: [],
            value: [],
            roleId: null,
            name: '',
            organizationId: '', // 组织机构id
            previewPeople: [] // 之前选择的人
        }
    },
    methods: {
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        openMember(val, val2) {
            this.organizationId = val2;
            this.name = val.roleName;
            this.previewPeople =  val.userList;
            // console.log(val)
            this.value = [];
            this.roleId = val.roleId;
            if (val.userList) {
                val.userList.forEach(item => {
                    this.value.push(item.id)
                })
            }
            // this.value = val.userList;
            this.data = val.userList;
            this.getNotSelect(val.roleId);
            this.dialogVisible = true;
        },
        // 获取组织机构未选择的人员
        async getNotSelect(val) {
            const res = await getNotMember({ roleId: val });
            console.log(res);
            if (res.code === 200) {
                this.data = [...this.data, ...res.data]

                // this.value = res.data;
            }
        },
        // 点击提交
        async submit() {
            console.log(this.value);
            console.log(this.previewPeople);
            // let notIdList = [];
            // console.log(this.data);
            let allList = [];
            // 取两个数组的差集
            allList = this.previewPeople.filter(item => {
                return this.value.indexOf(item.id) == -1
            });
            console.log(allList);
            // let notIdList = [];
            // if (allList.length > 0) {
            //     allList.forEach(item => {
            //         notIdList.push(item.id)
            //     })
            // }
            const res = await updateOrganizationMemberRole(JSON.stringify({ roleId: this.roleId, departmentId: this.organizationId, userIdList: this.value, userRoleIsNullIdList: allList }))
            console.log(res);
            if (res.code === 200) {
                this.$message({
                    showClose: true,
                    message: '修改成功',
                    type: 'success'
                });
                this.dialogVisible = false;
                this.$emit('getDetail');
            } else {
                this.$message({
                    showClose: true,
                    message: '修改失败！' + res.msg,
                    type: 'error'
                });
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.el-transfer {
    display: flex;
    justify-content: center;
    align-items: center;

}
</style>