<template>
    <!-- 新增预警 -->
    <div class="addNormal">
        <el-dialog title="新增预警" :visible.sync="dialogVisible" width="620px" :show-close="false">
            <div class="tableForm">
                <el-form v-if="ruleForm.type === 1 || ruleForm.type === 2" :model="ruleForm" :rules="rules" ref="ruleForm"
                    class="demo-ruleForm" size="small">
                    <el-form-item prop="dimensionId">
                        <span>维度名称</span>
                        <br>
                        <el-select v-model="ruleForm.dimensionId" placeholder="请选择维度">
                            <el-option v-for="item in DimensionList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="name">
                        <span>预警名称</span>
                        <br>
                        <el-input v-model="ruleForm.name" placeholder="仅限输入15个字符"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span>性别</span>
                        <br>
                        <el-select v-model="ruleForm.sex" placeholder="请选择性别">
                            <el-option label="男" :value="1"></el-option>
                            <el-option label="女" :value="2"></el-option>
                            <el-option label="全部" :value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <span>年龄</span>
                        <div class="ageLimit">
                            <el-form-item>
                                <el-input v-model="ruleForm.minAge" placeholder="请输入最小年龄"></el-input></el-form-item>
                            <span>至</span>
                            <el-form-item>
                                <el-input v-model="ruleForm.maxAge" placeholder="请输入最大年龄"></el-input></el-form-item>
                        </div>
                    </el-form-item>
                    <el-form-item prop="level">
                        <span>预警等级</span>
                        <br>
                        <el-select v-model="ruleForm.level" placeholder="请选择预警等级">
                            <el-option label="无" :value="0"></el-option>
                            <el-option label="一级" :value="1"></el-option>
                            <el-option label="二级" :value="2"></el-option>
                            <el-option label="三级" :value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <span>分值范围</span>
                        <div class="scoreLimit">
                            <el-form-item prop="minRange">
                                <el-input v-model="ruleForm.minRange" placeholder="请输入最小分值"></el-input></el-form-item>
                            <el-select v-model="ruleForm.firstSymbol" placeholder="请选择">
                                <el-option label="<" :value="1"></el-option>
                                <el-option label="≤" :value="2"></el-option>
                                <!-- <el-option label=">" :value="3"></el-option>
                                <el-option label="≥" :value="4"></el-option> -->
                            </el-select>
                            <span>分数</span>
                            <el-select v-model="ruleForm.secondSymbol" placeholder="请选择">
                                <el-option label="<" :value="1"></el-option>
                                <el-option label="≤" :value="2"></el-option>
                                <!-- <el-option label=">" :value="3"></el-option>
                                <el-option label="≥" :value="4"></el-option> -->
                            </el-select>
                            <el-form-item prop="maxRange">
                                <el-input v-model="ruleForm.maxRange" placeholder="请输入最大分值"></el-input></el-form-item>
                        </div>
                    </el-form-item>
                </el-form>
                <el-form v-else :model="ruleForm" :rules="rules2" ref="ruleForm" class="demo-ruleForm" size="small">
                    <el-form-item prop="dimensionId">
                        <span>单题计数名称</span>
                        <br>
                        <el-select v-model="ruleForm.dimensionId" placeholder="请选择单题计数">
                            <el-option v-for="item in DimensionList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="name">
                        <span>预警名称</span>
                        <br>
                        <el-input v-model="ruleForm.name" placeholder="仅限输入15个字符"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span>性别</span>
                        <br>
                        <el-select v-model="ruleForm.sex" placeholder="请选择性别">
                            <el-option label="男" :value="1"></el-option>
                            <el-option label="女" :value="2"></el-option>
                            <el-option label="全部" :value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <span>年龄</span>
                        <div class="ageLimit">
                            <el-form-item>
                                <el-input v-model="ruleForm.minAge" placeholder="请输入最小年龄"></el-input></el-form-item>
                            <span>至</span>
                            <el-form-item>
                                <el-input v-model="ruleForm.maxAge" placeholder="请输入最大年龄"></el-input></el-form-item>
                        </div>
                    </el-form-item>
                    <el-form-item prop="level">
                        <span>预警等级</span>
                        <br>
                        <el-select v-model="ruleForm.level" placeholder="请选择预警等级">
                            <el-option label="无" :value="0"></el-option>
                            <el-option label="一级" :value="1"></el-option>
                            <el-option label="二级" :value="2"></el-option>
                            <el-option label="三级" :value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <span>计数范围</span>
                        <div class="scoreLimit2">
                            <el-select v-model="ruleForm.symbol" placeholder="请选择">
                                <el-option v-for="item in $store.state.projectData" :key="item.id" :label="item.symbol" :value="item.id"></el-option>
                               
                                <!-- <el-option label=">" :value="1"></el-option>
                                <el-option label="≥" :value="2"></el-option>
                                <el-option label="<" :value="3"></el-option>
                                <el-option label="≤" :value="4"></el-option>
                                <el-option label="=" :value="5"></el-option>
                                <el-option label="范围内个数最多" :value="6"></el-option>
                                <el-option label="范围内个数最少" :value="7"></el-option>
                                <el-option label="所有答案中个数最多" :value="8"></el-option>
                                <el-option label="所有答案中个数最少" :value="9"></el-option> -->
                            </el-select>
                            <el-form-item prop="count">
                                <el-input v-model="ruleForm.count" placeholder="请输入数字" type="number"></el-input>
                            </el-form-item>


                        </div>
                    </el-form-item>
                    <!-- <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
                    </el-form-item> -->
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')" size="small">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { getDimensionList, getProjectList } from '@/api/gauge/dimension'
import { addWarning } from '@/api/gauge/warnning'
export default {
    data() {
        return {
            dialogVisible: false,
            ruleForm: {
                dimensionId: null,
                symbol: 1, // 单题计数对应的符号
                count: null, // 单题计数对应的数值
                firstSymbol: 1,
                level: 0,
                maxAge: null,
                maxRange: null,
                minAge: null,
                minRange: null,
                name: null,
                secondSymbol: 1,
                sex: 3,
                type: 1, // 1.维度 4.单题计数
            },
            DimensionList: [], // 维度列表
            rules: {
                name: [
                    { required: true, message: '请输入预警名称', trigger: 'blur' }
                    // {  max: 15, message: '仅限输入15个字符', trigger: 'blur' }
                ],
                dimensionId: [
                    { required: true, message: '请选择维度', trigger: 'change' }
                ],
                minAge: [
                    { required: true, message: '请输入最小年龄', trigger: 'blur' }
                ],
                maxAge: [
                    { required: true, message: '请输入最大年龄', trigger: 'blur' }
                ],
                minRange: [
                    { required: true, message: '请输入最小分值', trigger: 'blur' }
                ],
                maxRange: [
                    { required: true, message: '请输入最大分值', trigger: 'blur' }
                ]
            },
            rules2: {
                name: [
                    { required: true, message: '请输入预警名称', trigger: 'blur' }
                    // {  max: 15, message: '仅限输入15个字符', trigger: 'blur' }
                ],

                count: [
                    { required: true, message: '请输入数字', trigger: 'blur' }
                    // {  max: 15, message: '仅限输入15个字符', trigger: 'blur' }
                ],
                dimensionId: [
                    { required: true, message: '请选择单题计数', trigger: 'change' }
                ],
                minAge: [
                    { required: true, message: '请输入最小年龄', trigger: 'blur' }
                ],
                maxAge: [
                    { required: true, message: '请输入最大年龄', trigger: 'blur' }
                ]
            }
        };
    },
    methods: {
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        openAddWarning(val) {
            //刷新数据
            this.ruleForm = {
                dimensionId: null,
                symbol: 1, // 单题计数对应的符号
                count: null, // 单题计数对应的数值
                firstSymbol: 1,
                level: 0,
                maxAge: null,
                maxRange: null,
                minAge: null,
                minRange: null,
                name: null,
                secondSymbol: 1,
                sex: 3,
                type: 1, // 1.维度 4.单题计数
            }
            this.ruleForm.type = val;
            console.log(val); // 1为维度，4为单题计数
            if (val === 1 || val === 2) {
                this.getDimensionList();
                this.ruleForm.symbol = null;
            } else {
                this.getProjects();
                
            }

            this.dialogVisible = true;
        },
        // 获取项目数列表
        async getProjects() {
            const res = await getProjectList(sessionStorage.getItem('gaugeId'));
            console.log(res);
            if (res.code === 200) {
                this.DimensionList = res.data;
            }

        },
        // 获取所有维度
        async getDimensionList() {
            const res = await getDimensionList({ id: sessionStorage.getItem('gaugeId') });
            console.log(res)
            if (res.code === 200) {
                this.DimensionList = res.data
            }
        },
        // 提交
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    // alert('submit!');
                    // console.log(this.ruleForm)
                    const res = await addWarning(JSON.stringify(this.ruleForm));
                    console.log(res)
                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            message: '新增成功',
                            type: 'success'
                        });
                        this.$emit('getList');
                        this.dialogVisible = false;
                    } else {
                        this.$message({
                            showClose: true,
                            message: '新增失败' + res.msg,
                            type: 'error'
                        });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.addNormal {
    ::v-deep.el-dialog {
        margin-top: 10vh !important;
        margin-bottom: 0px !important;
        height: 75%;
        overflow-y: scroll;
        // position: relative;
    }

    // 设置模态框的样式
    ::v-deep.el-dialog__header {
        text-align: center;
        background-color: white;
        z-index: 2;
        position: sticky;
        top: 0;
    }

    ::v-deep.el-dialog__footer {
        text-align: center;
        background-color: white;
        z-index: 2;
        position: sticky;
        bottom: 0;

        .el-button {
            margin: 0 30px;
        }
    }

    ::v-deep.el-dialog__body {
        padding: 0 !important;
    }

    .tableForm {
        padding: 0 40px;

        .el-select {
            width: 100%;
        }

        .ageLimit,
        .scoreLimit {
            display: flex;
            height: 32px;

            span {
                display: inline-block;
                margin: 0 20px;
            }
        }

        .scoreLimit2 {
            display: flex;
            justify-content: space-between;

            .el-select,
            .el-input {
                width: 250px;
            }
        }

        .scoreLimit {
            justify-content: space-between;

            .el-input {
                width: 110px;
            }

            .el-select {
                width: 90px;
            }
        }

        .el-form-item {
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 20px;
            }

        }
    }
}
</style>