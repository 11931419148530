// 预警
import request from '@/utils/request'
// 获取预警的列表
export function getWarningList(params) {
  return request({
    url: '/gauge/gaugewarning/list',
    method: 'get',
    params
  });
}
// 新增预警
export function addWarning(data) {
    return request({
      url: '/gauge/save/gaugewarning',
      method: 'post',
      data
    });
}
// 修改预警
export function updateWarning(data) {
    return request({
      url: '/gauge/update/gaugewarning',
      method: 'post',
      data
    });
}
// 获取预警详情
export function getWarningDetail(params) {
    return request({
      url: '/gauge/gaugewarning',
      method: 'get',
      params
    });
}
// 删除预警
export function warningDelete(params) {
  return request({
    url: '/gauge/gaugewarning',
    method: 'delete',
    params
  });
}