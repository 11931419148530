<template>
    <div class="drawer">
        <!-- 量表预览 -->
        <el-drawer :title="gaugeData.gauge.name" :visible.sync="drawer" :direction="direction" size='50%'
            :show-close="false">
            <div class="content" ref="add" id="add">
                <div class="explain">
                    <span class="title">
                        介绍说明
                    </span>
                    <p class="text">
                        {{ gaugeData.gauge.memo }}
                    </p>
                </div>
                <div class="explain">
                    <span class="title">
                        引导语
                    </span>
                    <p class="text">
                        {{ gaugeData.gauge.guidelines }}
                    </p>
                </div>
                <div class="item" v-for="(item, index) in gaugeData.problemVOList" :key="item.problem.id">
                    <div>
                        <span class="type">第{{ item.problem.orderNum }}题</span>
                        <span class="type" v-if="item.problem.type === 1">(单选)</span>
                        <span class="type" v-else-if="item.problem.type === 2">(多选)</span>
                        <span class="type" v-else-if="item.problem.type === 3">(图片单选)</span>
                        <span class="type" v-else>(图片多选)</span>
                        <div class=".img">
                            <img :src="item.problem.url" alt="" v-if="item.problem.url !== ''">
                            <span class="question">{{ item.problem.name }}</span>
                        </div>
                        <ul v-if="item.problem.type === 1 || item.problem.type === 3">
                            <li v-for="ele in item.answerList" :key="ele.id">
                                <el-radio v-model="valueList[index]" :label="ele.name"></el-radio>
                                <div class="img">
                                    <img :src="ele.url" alt="" v-if="ele.url !== ''">
                                </div>
                            </li>
                        </ul>
                        <ul v-if="item.problem.type === 2 || item.problem.type === 4">
                            <li v-for="ele in item.answerList" :key="ele.id">
                                <!-- <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                                    <el-checkbox v-for="city in cities" :label="city" :key="city">{{ city }}</el-checkbox>
                                </el-checkbox-group> -->
                                <el-checkbox v-model="ele.id">{{ele.name}}</el-checkbox>
                                <!-- <img :key="ele.url" alt=""> -->
                                <div class="img">
                                    <img :src="ele.url" alt="" v-if="ele.url !== ''">
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="bottom">
                    <span @click="toTop">回到顶部</span>
                </div> -->
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { getGaugeDetail } from '../../../api/gauge/preview'
export default {
    data() {
        return {
            checked: false,
            val: '',
            valueList: [],
            drawer: false, // 控制模态框开关
            direction: 'rtl', // 控制模态框的打开方向
            gaugeId: null, // 当前量表的id
            gaugeData: {
                gauge: { name: '' },
                problemVOList: []
            } // 量表信息
        };
    },
    methods: {
        toTop() {
            // this.$refs.top.scrollTop = 0;
            document.querySelector('#add').scrollIntoView()
        },
        handleClose(done) {
            done();
            // this.$confirm('确认关闭？')
            //     .then(_ => {
            //         done();
            //     })
            //     .catch(_ => { });
        },
        // 打开当前模态框
        openPreview(val) {
            console.log(val)
            this.getGaugeDetailList(val);
            this.drawer = true;
        },
        // 获取当前量表详情
        async getGaugeDetailList(val) {
            this.valueList = [];
            const res = await getGaugeDetail({ id: val })
            console.log(res)
            if (res.code === 200) {
                this.gaugeData = res.data;
                this.gaugeData.problemVOList.forEach((item, index) => {
                    this.valueList.push({ val: '' })
                })
                console.log(this.valueList);
                // console.log(this.gaugeData.gauge);
            }
        }
    }
};
</script>

<style scoped lang="scss">
.drawer {
    position: relative;
    // ::v-deep.el-drawer__header {
    //     text-align: center !important;
    // }
    

    .content {
        padding: 0 40px;
        padding-bottom: 30px;
        
        .bottom{
            position: absolute;
            right: 20px;
            bottom: 50px;
        }

        .explain {
            .title {
                height: 20px;
                font-size: 14px;
                font-weight: 600;
                color: #303030;
                line-height: 20px;
            }

            .text {
                min-height: 85px;
                font-size: 14px;
                font-weight: 400;
                color: #7D7D7D;
                line-height: 20px;
            }
        }

        .item {
            margin-top: 20px;

            .type {
                font-size: 14px;
                font-weight: 600;
                color: #2D2D2D;
                line-height: 20px;
            }

            .question {
                // display: inline-block;
                // ma
                font-size: 14px;
                font-weight: 400;
                color: #2D2D2D;
                line-height: 20px;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    font-size: 14px;
                    font-weight: 400;
                    color: #7D7D7D;
                    line-height: 20px;
                    margin-top: 15px;
                }
            }
        }
    }

    img {
        height: 80px;
        width: 80px;
        display: inline-block;
        margin-right: 10px;
    }
}
</style>