<template>
  <div class="detail">
    <!-- 量表介绍 -->
    <!-- <button @click="toadd">跳转</button> -->
    <div class="tableForm">
      <el-form size="small" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="量表名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="显示名称" prop="showName">
          <el-input v-model="ruleForm.showName"></el-input>
        </el-form-item>
        <el-form-item label="量表状态">
          <el-select v-model="ruleForm.status" placeholder="请选择量表状态">
            <el-option label="草稿" :value="0"></el-option>
            <el-option label="未完成" :value="1"></el-option>
            <el-option label="已发布" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="量表类别" prop="gaugeTypeId">
          <el-select v-model="ruleForm.gaugeTypeId" placeholder="请选择量表类别">
            <el-option v-for="item in gaugeTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <span class="addBtn" @click="openAddGaugeType">
            <el-button type="primary" size="mini">管理</el-button>
            <!-- <i class="el-icon-circle-plus"></i> -->
          </span>
        </el-form-item>
        <el-form-item label="所属机构" prop="departmentIds">
          <el-cascader :options="options" :show-all-levels="false" :props="{ value: 'id', label: 'name', checkStrictly: true, emitPath: false, multiple: true,  }"
            v-model="ruleForm.departmentIds"></el-cascader>
          <!-- <el-select v-model="ruleForm.departmentId" placeholder="请选择所属部门">
            <el-option label="部门1" value="1"></el-option>
            <el-option label="部门2" value="2"></el-option> 
          </el-select>-->
        </el-form-item>
        <el-form-item label="团体报告" prop="isReport">
          <el-select v-model="ruleForm.isReport" placeholder="请选择是否生成团体报告">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年龄">
          <div class="age">
            <el-form-item>
              <el-input v-model="ruleForm.minAge" style="width:250px" placeholder="请输入最小年龄"></el-input>
            </el-form-item>
            <span style="display:inline-block; margin: 0 23px">至</span>
            <el-form-item>
              <el-input v-model="ruleForm.maxAge" style="width:250px" placeholder="请输入最大年龄"></el-input>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item label="介绍说明" prop="memo">
          <!-- <el-input type="textarea" v-model="ruleForm.memo"></el-input> -->
          <el-input type="textarea" :autosize="{ minRows: 2 }" placeholder="请输入内容" v-model="ruleForm.memo">
          </el-input>
        </el-form-item>
        <el-form-item label="指导语" prop="guidelines">
          <!-- <el-input type="textarea" v-model="ruleForm.guidelines"></el-input> -->
          <el-input type="textarea" :autosize="{ minRows: 2 }" placeholder="请输入内容" v-model="ruleForm.guidelines">
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn" id="btn">
            <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
            <!-- <el-button type="primary" @click="status = true" v-else>编辑</el-button> -->
          </div>

          <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <TypeManagementVue ref="child" @getGaugeTypeList="getGaugeTypeList"></TypeManagementVue>
  </div>
</template>

<script>
import { getGaugeType, addGaugeType, addGaugeIntroduction, getDetail } from '@/api/gauge/gaugeIntroduction.js'
import TypeManagementVue from './TypeManagement.vue';
import { getOrganizationList, addOrganization } from '@/api/system/organization'
export default {
  data() {
    return {
      options: null,
      ruleForm: {
        name: "", // 名称
        showName: "", // 显示名称
        gaugeTypeId: null, // 量表类别
        departmentIds: null, // 所属部门
        isReport: null, // 团体报告
        minAge: null, // 最小年龄
        maxAge: null, // 最大年龄
        memo: '', // 介绍说明
        guidelines: '', // 指导语
        status: 0
      },
      gaugeTypeList: [],
      rules: {
        name: [
          { required: true, message: "请输入量表名称", trigger: "blur" },
          { max: 20, message: "长度在 20 个字符内", trigger: "change" }
        ],
        departmentIds: [
          { required: true, message: "请选择所属部门", trigger: "change" }
        ],
        gaugeTypeId: [
          { required: true, message: "请选择量表类别", trigger: "change" }
        ],
        isReport: [
          { required: true, message: "请选择是否生成团体报告", trigger: "change" }
        ],
        showName: [
          { required: true, message: "请输入显示名称", trigger: "blur" },
          { max: 20, message: "长度在 20 个字符内", trigger: "change" }
        ],
        minAge: [
          { required: true, message: "请输入最小年龄", trigger: "blur" }
        ],
        maxAge: [
          { required: true, message: "请输入最大年龄", trigger: "blur" }
        ],
        guidelines: [
          { max: 500, message: '长度在500字以内', trigger: 'change' }
        ],
        memo: [
          { max: 500, message: '长度在500字以内', trigger: 'change' }
        ]
      }
    };
  },
  methods: {
    toadd() {
      // document.querySelector('#btn').scrollIntoView()
    },
    // 点击提交
    submitForm(formName) {
      // console.log(this.$store.state.gaugeList.gaugeId)
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (sessionStorage.getItem('gaugeId') !== null) {
            this.ruleForm.id = sessionStorage.getItem('gaugeId');
          }
          const res = await addGaugeIntroduction(JSON.stringify(this.ruleForm));
          console.log(res);
          if (res.code === 200) {
            sessionStorage.setItem('gaugeId', res.data)
            this.$store.commit('gaugeList/changeGaugeId', res.data)
            this.$message({
              showClose: true,
              message: '保存成功！',
              type: 'success'
            });
            // this.status = false;
            this.getGaugeDetail();
            setTimeout(() => {
              this.$router.push('/home/addGauge/titleEditor')
            }, 1000);
          } else {
            this.$message({
              showClose: true,
              message: '保存失败' + res.msg,
              type: 'error'
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 获取组织机构列表
    async getAllList() {
      const res = await getOrganizationList()
      console.log(res);
      if (res.code === 200) {
        this.options = res.data;
        // this.options.push({ id: 0, name: '无(第一级)' })
        this.deleteNullChildren(this.options)
      }
    },
    // 使用递归取出children为空的数据
    deleteNullChildren(val) {
      // console.log(val);
      if (val) {
        val.forEach(item => {
          if (item.children) {
            if (item.children.length < 1) {
              delete item.children;
            }
          }
          this.deleteNullChildren(item.children)
        });
      }
    },
    // 获取量表类型列表
    async getGaugeTypeList() {
      const res = await getGaugeType();
      console.log(res);
      if (res.code === 200) {
        this.gaugeTypeList = res.data;
      }
    },
    // 点击量表类别管理
    openAddGaugeType() {
      this.$refs.child.openType();
      // this.$prompt('请输入新增量表类别', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      // }).then(async ({ value }) => {
      //   const res = await addGaugeType({ name: value })
      //   console.log(res);
      //   if (res.code === 200) {
      //     this.$message({
      //       type: 'success',
      //       message: '新增成功'
      //     });
      //   }
      //   this.getGaugeTypeList()
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '取消新增量表类别'
      //   });
      // });
    },
    // 获取量表详情
    async getGaugeDetail() {
      console.log(sessionStorage.getItem('gaugeId'))
      if (sessionStorage.getItem('gaugeId') === null) {
        // this.status = true;
        this.ruleForm = {
          name: "", // 名称
          showName: "", // 显示名称
          gaugeTypeId: null, // 量表类别
          departmentIds: null, // 所属部门
          isReport: '1', // 团体报告
          minAge: null, // 最小年龄
          maxAge: null, // 最大年龄
          memo: '', // 介绍说明
          guidelines: '', // 指导语
        }
      } else {

        const res = await getDetail({ id: sessionStorage.getItem('gaugeId') })
        console.log(res);
        if (res.code === 200) {
          this.ruleForm = res.data;
        }
      }

    }
  },
  created() {
    this.getGaugeTypeList();
    this.getGaugeDetail();
    this.getAllList();
  },
  components: { TypeManagementVue }
};
</script>

<style lang="scss" scoped>
.detail {
  padding-top: 30px;
  // height: calc(100vh - 160px);

  .tableForm {
    .el-form-item {
      margin-bottom: 18px !important;

      
    }

    .el-input,
    .el-select,
    .el-cascader,
    .el-textarea {
      width: 560px;
    }

    .age {
      display: flex;
      height: 32px;
    }

    .btn {
      // text-align: center;
      padding-top: 30px;
      padding-left: 200px;
    }

    .addBtn {
      display: inline-block;
      margin-left: 10px;

      i {
        display: inline-block;
        width: 22px;
        height: 22px;
        color: #555;
      }
    }
  }
}
</style>