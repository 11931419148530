<template>
    <!-- 编辑结果--单题计数 -->
    <div class="addNormal">
        <el-dialog title="编辑结果解释" :visible.sync="dialogVisible" width="620px" :show-close="false">
            <div class="tableForm">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" size="small">
                    <el-form-item prop="name">
                        <span>结果名称</span>
                        <br>
                        <el-input v-model="ruleForm.name" placeholder="仅限输入15个字符"></el-input>
                    </el-form-item>
                    <el-form-item >
                        <span>结果参考值</span>
                        <br>
                        <el-input v-model="ruleForm.reference" placeholder="请输入结果参考值"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span>性别</span>
                        <br>
                        <el-select v-model="ruleForm.sex" placeholder="请选择性别">
                            <el-option label="男" :value="1"></el-option>
                            <el-option label="女" :value="2"></el-option>
                            <el-option label="全部" :value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <span>年龄</span>
                        <div class="ageLimit">
                            <el-form-item>
                                <el-input v-model="ruleForm.minAge" placeholder="请输入最小年龄"></el-input>
                            </el-form-item><span>至</span>
                            <el-form-item>
                                <el-input v-model="ruleForm.maxAge" placeholder="请输入最大年龄"></el-input>
                            </el-form-item>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <span>预警等级</span>
                        <br>
                        <el-select v-model="ruleForm.level" placeholder="请选择等级">
                            <el-option label="无" :value="0"></el-option>
                            <el-option label="一级" :value="1"></el-option>
                            <el-option label="二级" :value="2"></el-option>
                            <el-option label="三级" :value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <span>计数范围</span>
                        <div class="scoreLimit">
                            <el-select v-model="ruleForm.countSymbol" placeholder="请选择">
                                <el-option v-for="item in $store.state.projectData" :key="item.id" :label="item.symbol" :value="item.id"></el-option>
                                <!-- <el-option label=">" :value="1"></el-option>
                                <el-option label="≥" :value="2"></el-option>
                                <el-option label="<" :value="3"></el-option>
                                <el-option label="≤" :value="4"></el-option>
                                <el-option label="=" :value="5"></el-option>
                                <el-option label="范围内个数最多" :value="6"></el-option>
                                <el-option label="范围内个数最少" :value="7"></el-option>
                                <el-option label="所有答案中个数最多" :value="8"></el-option>
                                <el-option label="所有答案中个数最少" :value="9"></el-option> -->
                            </el-select>
                            <el-form-item prop="count">
                                <el-input v-model="ruleForm.count" placeholder="请输入数字" type="number"></el-input>
                            </el-form-item>
                            
                            
                        </div>
                    </el-form-item>
                    <el-form-item prop="memo">
                        <span>结果解释</span>
                        <br>
                        <el-input type="textarea" autosize placeholder="请输入分值说明（限制500字以内）" v-model="ruleForm.memo">
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="proposal">
                        <span>辅导建议</span>
                        <br>
                        <el-input type="textarea" autosize placeholder="请输入辅导建议（限制500字以内）" v-model="ruleForm.proposal">
                        </el-input>
                    </el-form-item>
                    <!-- <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
                    </el-form-item> -->
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')" size="small">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { updateProjectNormal, projectDetail } from '@/api/gauge/normal'
export default {
    data() {
        return {
            dialogVisible: false,
            ruleForm: {
                gaugeProjectId: null, // 项目数id
                countSymbol: 1, // 符号
                level: 0, //预警等级
                maxAge: null, // 最大年龄
                // maxRange: null, // 最大分值
                memo: '', // 分值说明
                minAge: null, // 最小年龄
                count: null, // 分值
                name: '', // 常模名称
                proposal: '', // 指导建议
                degree: null, // 常模程度
                // secondSymbol: 1, // 第二个符号
                reference: null, // 常模参考值
                sex: 3 // 性别
            },
            rules: {
                name: [
                    { required: true, message: '请输入常模名称', trigger: 'blur' },
                    { min: 1, max: 15, message: '长度在 1 到 15 个字符', trigger: 'change' }
                ],
                minAge: [
                    { required: true, message: '请输入最小年龄', trigger: 'blur' }
                ],
                maxAge: [
                    { required: true, message: '请输入最大年龄', trigger: 'blur' }
                ],
                count: [
                    { required: true, message: '请输入数字', trigger: 'blur' }
                ],
                // maxRange: [
                //     { required: true, message: '请输入最大分值', trigger: 'blur' }
                // ],
                proposal: [
                    { max: 500, message: '长度在500字以内', trigger: 'change' }
                ],
                memo: [
                    { max: 500, message: '长度在500字以内', trigger: 'change' }
                ]
            },
            projectId: null
        };
    },
    methods: {
        // 控制模态框
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        // 打开当前模态框
        openEditNormal(val1,val) {
            
            // this.ruleForm.gaugeProjectId = val;
            this.projectId = val;
            this.getNormalDetail(val);
            this.dialogVisible = true;
        },
        // 提交
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    // alert('submit!');
                    console.log(this.ruleForm)
                    // this.ruleForm.count = Number(this.ruleForm.count)
                    const res = await updateProjectNormal(this.projectId,JSON.stringify(this.ruleForm))
                    console.log(res)
                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            message: '修改成功！',
                            type: 'success'
                        });
                        this.$emit('changeDimension')
                        this.dialogVisible = false;
                    } else {
                        this.$message({
                            showClose: true,
                            message: '修改失败！' + res.msg,
                            type: 'error'
                        });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 获取当前结果解释详情
        async getNormalDetail(val) {
            const res = await projectDetail(val)
            console.log(res)
            if (res.code === 200) {
                this.ruleForm = res.data;
                delete this.ruleForm.createTime;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.addNormal {
    ::v-deep.el-dialog {
        margin-top: 10vh !important;
        margin-bottom: 0px !important;
        height: 75%;
        overflow-y: scroll;
        // position: relative;
    }

    // 设置模态框的样式
    ::v-deep.el-dialog__header {
        text-align: center;
        background-color: white;
        z-index: 2;
        position: sticky;
        top: 0;
    }

    ::v-deep.el-dialog__footer {
        text-align: center;
        background-color: white;
        z-index: 2;
        position: sticky;
        bottom: 0;

        .el-button {
            margin: 0 30px;
        }
    }

    ::v-deep.el-dialog__body {
        padding: 0 !important;
    }

    .tableForm {
        padding: 0 40px;

        .el-select {
            width: 100%;
        }

        // height: 80%;
        // overflow-y: scroll;
        .ageLimit,
        .scoreLimit {
            display: flex;
            height: 32px;

            span {
                display: inline-block;
                margin: 0 20px;
            }
        }

        .scoreLimit {
            justify-content: space-between;

            .el-input, .el-select {
                width: 250px;
            }

            // .el-select {
            //     width: 90px;
            // }
        }

        .el-form-item {
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 20px;
            }
        }
    }
}
</style>