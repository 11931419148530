<template>
  <div class="division">
    <!-- 维度划分 -->
    <!-- <div class="chart">
      <div class="type">
        <span>图表显示</span>
        <el-dropdown size="small" split-button type="primary" @command="handleCommand">
          {{ commandVal }}
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">柱状图</el-dropdown-item>
            <el-dropdown-item command="2">元饼图</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="chartShow">
        图表显示区域
      </div>
    </div> -->
    <div class="divisionList">
      <div class="title">
        <!-- <span>维度列表</span> -->
        <el-button type="primary" size="small" @click="addDivision">新增维度</el-button>
        <el-button type="primary" size="small" @click="addProject">新增单题计数</el-button>
      </div>
      <div class="list">
        <el-table :data="tableData" style="width: 100%" size="small">
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item v-for="item in props.row.problemOrderNumList" :key="item.id" label=" " label-width="60px">
                  <span>题号</span>
                  <span>{{ item.orderNum }}</span>
                  <span>&nbsp;&nbsp;&nbsp;{{ item.name }}</span>
                </el-form-item>
                <!-- <el-form-item label="所属店铺">
                  <span>{{ props.row.shop }}</span>
                </el-form-item> -->
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="维度名称" prop="name" width="150">
          </el-table-column>
          <el-table-column label="包含题目">
            <template slot-scope="props">
              <span v-for="(item, index) in  props.row.problemOrderNumList" :key="item.id">
                <span>{{ item.orderNum }}</span>
                <span v-if="index !== (props.row.problemOrderNumList.length - 1)">、</span>
              </span>
              <!-- <el-tooltip effect="light" placement="top">
                <p slot="content" style="width:500px">
                  <span v-for="(item, index) in  props.row.problemOrderNumList" :key="item.id">
                    <span>{{ item.orderNum }}</span>
                    <span v-if="index !== (props.row.problemOrderNumList.length - 1)">、</span>
                  </span>
                </p>
                <p class="textContent">
                  <span v-for="(item, index) in  props.row.problemOrderNumList" :key="item.id">
                    <span>{{ item.orderNum }}</span>
                    <span v-if="index !== (props.row.problemOrderNumList.length - 1)">、</span>
                  </span>
                </p>
              </el-tooltip> -->
            </template>
          </el-table-column>

          <el-table-column label="公式">

            <template slot-scope="scope">
              <span class="textContent">{{ scope.row.formula }}</span>
              <!-- <el-tooltip effect="light" placement="top">
                <p style="width:500px" slot="content">{{ scope.row.formula }}</p>
                <span class="textContent">{{ scope.row.formula }}</span>
              </el-tooltip> -->
            </template>
          </el-table-column>
          <el-table-column label="是否在图表中显示" width="140">
            <template slot-scope="scope">
              <el-switch @change="changeShow(scope.row)" style="display: block; height:22px" v-model="scope.row.isShow" 
              :active-value="true" :inactive-value="false">
            </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="props">
              <el-button type="text" @click="editDivision(props.row)">编辑</el-button>
              <el-button type="text" @click="deleteDimension(props.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-table v-if="projectList.length > 0" :data="projectList" border style="width: 100%; margin-top: 20px">
          <el-table-column prop="name" label="名称" width="200">
          </el-table-column>
          <el-table-column label="分值设定" width="200">
            <template slot-scope="scope">
              {{ scope.row.score }}
              <span v-if="scope.row.symbol === 1 || scope.row.symbol === '1'"> {{ '<' }} </span>
                  <span v-if="scope.row.symbol === 2 || scope.row.symbol === '2'"> ≤ </span>
                  <span v-if="scope.row.symbol === 3 || scope.row.symbol === '3'"> > </span>
                  <span v-if="scope.row.symbol === 4 || scope.row.symbol === '4'"> ≥ </span>
                  <span>X</span>
                  <span v-if="scope.row.secondSymbol === 1 || scope.row.secondSymbol === '1'"> {{ '<' }} </span>
                      <span v-if="scope.row.secondSymbol === 2 || scope.row.secondSymbol === '2'"> ≤ </span>
                      <span v-if="scope.row.secondSymbol === 3 || scope.row.secondSymbol === '3'"> > </span>
                      <span v-if="scope.row.secondSymbol === 4 || scope.row.secondSymbol === '4'"> ≥ </span>
                      {{ scope.row.maxScore }}
            </template>
          </el-table-column>
          <!-- <el-table-column
      prop="score"
      label="分值" width="200">
    </el-table-column> -->
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="editProject(scope.row)">编辑</el-button>
              <el-button type="text" @click="deleteProject(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <EditDimensionVue ref="childEdit" @getList="getList"></EditDimensionVue>
    <AddDimensionVue ref="child" @getList="getList"></AddDimensionVue>
    <AddProjectVue ref="addProjectChild" @getProjects="getProjects"></AddProjectVue>
    <EditProject ref="editProjectChild" @getProjects="getProjects"></EditProject>
  </div>
</template>

<script>
import { getDimensionList, deleteDimension, updateDimension,getProjectList,deleteProjectList } from '@/api/gauge/dimension'
import AddDimensionVue from './AddDimension.vue'
import EditDimensionVue from './EditDimension.vue'
import AddProjectVue from './AddProject.vue'
import EditProject from './EditProject.vue'
export default {
  data() {
    return {
      tableData: [],
      projectList: [], 
      commandVal: '柱状图' // 图形显示 
    }
  },
  methods: {
    // 点击新增维度
    addDivision() {
      if (sessionStorage.getItem('gaugeId') === 'null') {
        console.log(1111)
        this.$message({
          showClose: true,
          message: '请先确认量表介绍',
          type: 'info'
        });
      } else {
        this.$refs.child.openAdd();
      }

    },
    // 新增项目数
    addProject() {
      console.log(111)
      this.$refs.addProjectChild.open();
    },
    // 编辑项目数
    editProject(val) {
      // console.log(111)
      this.$refs.editProjectChild.open(val);
    },
    // 获取项目数列表
    async getProjects() {
      const res = await getProjectList(sessionStorage.getItem('gaugeId'));
      console.log(res);
      if(res.code === 200) {
        this.projectList = res.data;
      }

    },
    editDivision(val) {
      this.$refs.childEdit.openAdd(val);
    },
    // 删除项目数
    async deleteProject(val) {
      // console.log(val)
      // let arr = [val]
      const res = await deleteProjectList([val]);
      console.log(res);
      if(res.code === 200) {
        this.$message({
          showClose: true,
          message: '删除成功！',
          type: 'success'
        });
        this.getProjects();
      } else {
        this.$message({
          showClose: true,
          message: '删除失败！',
          type: 'error'
        });
      }
    },
    // 选择图形
    handleCommand(command) {
      // this.$message('click on item ' + command);
      console.log(command)
      if (command === '1') {
        this.commandVal = '柱状图'
      } else if (command === '2') {
        this.commandVal = '元饼图'
      }
    },
    // 获取维度列表
    async getList() {
      const res = await getDimensionList({ id: sessionStorage.getItem('gaugeId') });
      console.log(res)
      if (res.code === 200) {
        this.tableData = JSON.parse(JSON.stringify(res.data));
      }
    },
    // 删除维度
    deleteDimension(val) {
      this.$confirm('确认删除该条维度?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await deleteDimension({ id: val })
        console.log(res)
        if (res.code === 200) {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          });
          this.getList();
        } else {
          this.$message({
            showClose: true,
            message: '删除失败' + res.msg,
            type: 'error'
          });
        }
      }).catch(() => {
        this.$message({
          showClose: true,
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 修改是否显示
    async changeShow(val) {
      console.log(val)
      let info = {id: val.id, isShow: val.isShow, gaugeId: sessionStorage.getItem('gaugeId')}
      const res = await updateDimension(JSON.stringify(info))
      console.log(res);
      this.getList();
    }
  },
  components: {
    AddDimensionVue,
    EditDimensionVue,
    AddProjectVue,
    EditProject
  },
  created() {
    this.getList();
    this.getProjects();
  }
}
</script>

<style scoped lang="scss">
.division {
  padding-bottom: 10px;
  // ::v-deep.el-table .el-table__cell {
  //       padding: 5px 0;
  //   }

  // height: calc(100vh - 160px);
  .chart {
    margin-top: 30px;

    .type {
      .el-dropdown {
        margin-left: 20px;
      }
    }

    .chartShow {
      margin-top: 15px;
      width: 517px;
      height: 219px;
      background: #F0F0F0;
    }
  }

  .divisionList {
    margin-top: 30px;

    .demo-table-expand {
      font-size: 0;
    }

    .demo-table-expand label {
      width: 90px;
      color: #99a9bf;
    }

    .demo-table-expand .el-form-item {
      margin-right: 0;
      margin-bottom: 0;
      width: 50%;
    }

    // .textContent {
    //   display: inline-block;
    //   text-overflow: -o-ellipsis-lastline;
    //   overflow: hidden; // 溢出隐藏
    //   text-overflow: ellipsis; // 超出显示...
    //   display: -webkit-box;
    //   -webkit-line-clamp: 1;
    //   -webkit-box-orient: vertical; // 超出多少行显示...
    // }

    .title {
      font-size: 14px;

      // .el-button {
      //   margin-left: 20px;
      // }
    }

    .list {
      margin-top: 20px;

      // ::v-deep.el-table .el-table__cell {
      //   height: 35px !important;
      //   padding: 0 !important;
      // }
      // ::v-deep.el-table td.el-table__cell div {
      //   height: 32px;
      //   line-height: 32px;

      // }
    }

    ::v-deep.el-form-item {
      width: 100% !important;
    }
  }
}
</style>