<template>
    <div class="earning">
        <!-- 预警设置 -->
        <!-- <button @click="toadd">跳转</button> -->
        <div class="choice">
            <span>是否预警</span>
            <el-switch v-model="value" @change="choice" :active-value="1" :inactive-value="0">
            </el-switch>
        </div>
        <div class="searchBtn">
            <el-select v-model="searchInfo.dimensionId" clearable placeholder="请选择维度名称" size="small"
                @change="changeSearchInfo">
                <el-option v-for="item in DimensionList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <el-select v-model="searchInfo.type" clearable placeholder="请选择类别" size="small" @change="changeSearchInfo">
                <el-option label="自建" :value="1">
                </el-option>
                <el-option label="常模" :value="2">
                </el-option>
            </el-select>
            <el-select v-model="searchInfo.level" clearable placeholder="请选择级别" size="small" @change="changeSearchInfo">
                <el-option label="无" :value="0"></el-option>
                <el-option label="一级" :value="1"></el-option>
                <el-option label="二级" :value="2"></el-option>
                <el-option label="三级" :value="3"></el-option>
            </el-select>
            <el-button type="primary" size="small" @click="changeSearchInfo">搜索</el-button>
            <!-- <el-button type="primary" size="small" @click="openAdd">新增</el-button> -->
            <el-dropdown size="small" split-button type="primary" @command="openAdd">
                新增
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command=1>维度预警</el-dropdown-item>
                    <el-dropdown-item :command=4>单题计数预警</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div class="tableContent">
            <el-table :data="tableData" style="width: 100%">
                <el-table-column fixed prop="type" label="来源类别" width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type === 1">自建</span>
                        <span v-else-if="scope.row.type === 2">常模</span>
                        <!-- 单题计数的常模和自建 -->
                        <span v-else-if="scope.row.type === 3">常模</span>
                        <span v-else-if="scope.row.type === 4">自建</span>
                    </template>
                </el-table-column>
                <el-table-column prop="dimensionName" label="维度名称" width="150">
                </el-table-column>
                <el-table-column prop="name" label="预警名称" width="300">
                </el-table-column>
                <el-table-column label="年龄" width="150">
                    <template slot-scope="scope">
                        <div>
                            <span>{{ scope.row.minAge }}</span>
                            <span v-if="scope.row.maxAge !== null && scope.row.minAge !== null">-</span>
                            <span v-if="scope.row.maxAge === null && scope.row.minAge !== null">岁以上</span>
                            <span>{{ scope.row.maxAge }}</span>
                            <span v-if="scope.row.minAge === null && scope.row.maxAge !== null">岁以下</span>
                        </div>

                    </template>
                </el-table-column>
                <el-table-column prop="sex" label="性别">
                    <template slot-scope="scope">
                        <span v-if="scope.row.sex === 1">男</span>
                        <span v-if="scope.row.sex === 2">女</span>
                        <span v-if="scope.row.sex === 3">全部</span>
                    </template>
                </el-table-column>
                <el-table-column label="预警级别">
                    <template slot-scope="scope">
                        <span v-if="scope.row.level === 0">无</span>
                        <span v-if="scope.row.level === 1">一级</span>
                        <span v-if="scope.row.level === 2">二级</span>
                        <span v-if="scope.row.level === 3">三级</span>
                    </template>
                </el-table-column>
                <el-table-column label="分值范围" width="150" >
                    <template slot-scope="scope">
                        <template v-if="scope.row.type === 1 || scope.row.type === 2">
                            <span>{{ scope.row.minRange }}</span>
                        <span v-if="scope.row.firstSymbol === 1">＜</span>
                        <span v-if="scope.row.firstSymbol === 2">≤</span>
                        <span v-if="scope.row.firstSymbol === 3">＞</span>
                        <span v-if="scope.row.firstSymbol === 4">≥</span>
                        <span>X</span>
                        <span v-if="scope.row.secondSymbol === 1">＜</span>
                        <span v-if="scope.row.secondSymbol === 2">≤</span>
                        <span v-if="scope.row.secondSymbol === 3">＞</span>
                        <span v-if="scope.row.secondSymbol === 4">≥</span>
                        <span>{{ scope.row.maxRange }}</span>
                        </template>
                        <template v-else>
                            {{ scope.row.symbol }}
                            <span>{{ scope.row.count }}</span>
                        </template>
                        <!-- <template v-else>
                            <span v-if="scope.row.symbol === 1">＞</span>
                            <span v-if="scope.row.symbol === 2">≥</span>
                            <span v-if="scope.row.symbol === 3">＜</span>
                            <span v-if="scope.row.symbol === 4">≤</span>
                            <span v-if="scope.row.symbol === 5">=</span>
                            <span v-if="scope.row.symbol === 6">范围内个数最多&nbsp;&nbsp;&nbsp;</span>
                            <span v-if="scope.row.symbol === 7">范围内个数最少&nbsp;&nbsp;&nbsp;</span>
                            <span v-if="(scope.row.symbol === 8)">所有答案中个数最多&nbsp;&nbsp;&nbsp;</span>
                            <span v-if="(scope.row.symbol === 9)">所有答案中个数最少&nbsp;&nbsp;&nbsp;</span>

                            <span>{{ scope.row.count }}</span>
                        </template> -->
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <!-- <el-button @click="editWarning(scope.row)" type="text" size="small">查看</el-button> -->
                        <el-button v-show="scope.row.type !== 2 && scope.row.type !== 3" type="text" size="small" @click="editWarning(scope.row)">编辑</el-button>
                        <el-button @click="deleteWarning(scope.row.id)" type="text" size="small" v-show="scope.row.type !== 2 && scope.row.type !== 3">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- <div id="add">11</div> -->
        <AddWarning ref="child" @getList="getList"></AddWarning>
        <EditWarningVue ref="childEdit" @getList="getList"></EditWarningVue>
    </div>
</template>
<script>
import AddWarning from './AddWarning.vue'
import EditWarningVue from './EditWarning.vue'
import { getWarningList, warningDelete } from '@/api/gauge/warnning'
import { getDimensionList } from '@/api/gauge/dimension'
import { addGaugeIntroduction, getDetail } from '@/api/gauge/gaugeIntroduction.js'
export default {
    components: { AddWarning, EditWarningVue },
    data() {
        return {
            value: true, // 是否预警
            tableData: [],
            searchInfo: {
                dimensionId: null, // 维度id
                level: null, // 等级
                type: null // 类型
            },
            DimensionList: [] // 维度列表
        }
    },
    methods: {
        // 点击新增
        openAdd(val) {
            if (sessionStorage.getItem('gaugeId') === 'null') {
                // console.log(1111)
                this.$message({
                    showClose: true,
                    message: '请先确认量表介绍',
                    type: 'info'
                });
            } else {
                // console.log(val)
                this.$refs.child.openAddWarning(val)
            }
        },
        // 点击编辑
        editWarning(val) {
            this.$refs.childEdit.openEditWarning(val)
        },
        // 获取预警列表
        async getList() {
            const res = await getWarningList({ gaugeId: sessionStorage.getItem('gaugeId'), ...this.searchInfo })
            console.log(res)
            if (res.code === 200) {
                // this.tableData = res.data;
                this.tableData = this.$utils.ToType(res.data)
            }
        },
        // 获取所有维度
        async getDimensionList() {
            const res = await getDimensionList({ id: sessionStorage.getItem('gaugeId') });
            //   console.log(res)
            if (res.code === 200) {
                this.DimensionList = res.data
            }
        },
        // 选择搜素内容
        changeSearchInfo() {
            this.getList()
        },
        // 删除预警
        deleteWarning(val) {
            console.log(val)
            this.$confirm('确认删除该预警?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const res = await warningDelete({ id: val })
                console.log(res)
                if (res.code === 200) {
                    this.$message({
                        showClose: true,
                        type: 'success',
                        message: '删除成功!'
                    });
                    // console.log(111)
                    this.getList();
                } else {
                    this.$message({
                        showClose: true,
                        type: 'error',
                        message: '删除失败!' + res.msg
                    });
                }
            }).catch(() => {
                this.$message({
                    showClose: true,
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 是否预警
        async choice() {
            console.log(this.value)
            // let warning = null;
            // if (this.value === true) {
            //     warning = 1;
            // } else {
            //     warning = 0;
            // }
            const res = await addGaugeIntroduction({ id: sessionStorage.getItem('gaugeId'), isWarning: this.value })
            console.log(res)
            if (res.code === 200) {
                this.$message({
                    showClose: true,
                    type: 'success',
                    message: '修改成功!'
                });
            } else {
                this.$message({
                    showClose: true,
                    type: 'info',
                    message: '修改失败!'
                });
            }
        },
        // 获取量表详情
        async getGaugeDetail() {
            console.log(sessionStorage.getItem('gaugeId'))
            const res = await getDetail({ id: sessionStorage.getItem('gaugeId') })
            console.log(res);
            if (res.code === 200) {
                this.value = res.data.isWarning;
                // this.ruleForm = res.data;
            }


        }
    },
    created() {
        this.getList();
        this.getDimensionList();
        this.getGaugeDetail();
    }
}
</script>
<style lang="scss" scoped>
::v-deep.el-table .el-table__cell {
    height: 35px !important;
    padding: 0px !important;
    line-height: 35px !important;
}
::v-deep .el-button-group{
    .el-button{
        &:nth-child(2) {
            margin-left: 0px !important;
        }
    }
}
.earning {
    .choice {
        margin-top: 30px;

        span {
            font-size: 14px;
            display: inline-block;
            margin-right: 20px;
        }
    }

    .searchBtn {
        margin-top: 20px;

        // .el-select {
        //     width: 170px;
        //     // margin-left: 20px;
        //     margin-right: 40px;

        //     &:nth-child(1) {
        //         width: 270px;
        //     }
        // }
    }

    .tableContent {
        margin-top: 20px;
    }
}
</style>