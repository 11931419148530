function ToType(list) {
    if (list && list.length > 0) {
        list.forEach(e => {
            if (e.symbol === 1) {
                e.symbol = '>'
            } else if (e.symbol === 2) {
                e.symbol = '≥'
            } else if (e.symbol === 3) {
                e.symbol = '<'
            } else if (e.symbol === 4) {
                e.symbol = '≤'
            } else if (e.symbol === 5) {
                e.symbol = '='
            } else if (e.symbol === 6) {
                e.symbol = '范围内个数最多'
            } else if (e.symbol === 7) {
                e.symbol = '范围内个数最少'
            } else if (e.symbol === 8) {
                e.symbol = '所有答案中个数最多'
            } else if (e.symbol === 9) {
                e.symbol = '所有答案中个数最少'
            }
            if (e.countSymbol === 1) {
                e.countSymbol = '>'
            } else if (e.countSymbol === 2) {
                e.countSymbol = '≥'
            } else if (e.countSymbol === 3) {
                e.countSymbol = '<'
            } else if (e.countSymbol === 4) {
                e.countSymbol = '≤'
            } else if (e.countSymbol === 5) {
                e.countSymbol = '='
            } else if (e.countSymbol === 6) {
                e.countSymbol = '范围内个数最多'
            } else if (e.countSymbol === 7) {
                e.countSymbol = '范围内个数最少'
            } else if (e.countSymbol === 8) {
                e.countSymbol = '所有答案中个数最多'
            } else if (e.countSymbol === 9) {
                e.countSymbol = '所有答案中个数最少'
            }
        });
    }
    return list;
    // console.log(list);
}
export default {ToType};