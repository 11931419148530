<template>
    <div>
        <!-- 新增单题记数 -->
        <el-dialog :visible.sync="dialogVisible" width="620px" title="单题计数" :show-close="false">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" size="small">
                <!-- <el-form-item prop="name">
                    <span>维度名称</span>
                    <br />
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item> -->
                <el-form-item prop="name">
                    <span>计分统计名称</span>
                    <br />
                    <el-input placeholder="请输入计分统计名称" v-model="ruleForm.name" clearable>
                    </el-input>
                    <!-- <el-select v-model="ruleForm.name" placeholder="请选择">
                        <el-option label="阳性项目数" value="阳性项目数">
                        </el-option>
                        <el-option label="阴性项目数" value="阴性项目数">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item>
                    <span>分值设定</span>
                    <br />
                    <div class="scoreLimit">
                            <el-form-item prop="score">
                                <el-input v-model="ruleForm.score" placeholder="请输入最小值"></el-input>
                            </el-form-item>
                            <el-select v-model="ruleForm.symbol" placeholder="请选择">
                                <el-option label="<" :value="1"></el-option>
                                <el-option label="≤" :value="2"></el-option>
                                <!-- <el-option label=">" :value="3"></el-option>
                                <el-option label="≥" :value="4"></el-option> -->
                            </el-select>
                            <span>分数</span>
                            <el-select v-model="ruleForm.secondSymbol" placeholder="请选择">
                                <el-option label="<" :value="1"></el-option>
                                <el-option label="≤" :value="2"></el-option>
                                <!-- <el-option label=">" :value="3"></el-option>
                                <el-option label="≥" :value="4"></el-option> -->
                            </el-select>
                            <el-form-item prop="maxScore">
                                <el-input v-model="ruleForm.maxScore" placeholder="请输入最大值"></el-input>
                            </el-form-item>
                        </div>
                    <!-- <div class="scoreLimit">
                        <el-form-item prop="symbol">
                            <el-select v-model="ruleForm.symbol" placeholder="请选择">
                                <el-option label="<" :value="1"></el-option>
                                <el-option label="≤" :value="2"></el-option>
                                <el-option label=">" :value="3"></el-option>
                                <el-option label="≥" :value="4"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item prop="score">
                            <el-input v-model="ruleForm.score" placeholder="请输入分值" type="number"></el-input>
                        </el-form-item>

                    </div> -->
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" size="small" @click="submitForm('ruleForm')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { addProject } from '@/api/gauge/dimension'
export default {
    data() {
        return {
            dialogVisible: false,
            ruleForm: {
                name: null,
                symbol: 1,
                secondSymbol: 1, // 第二个符号
                score: null,
                maxScore: null
            },
            rules: {
                name: [
                    { required: true, message: '选择项目', trigger: 'blur' },

                ],
                score: [
                    { required: true, message: '请输入最小值', trigger: 'blur' }
                    // { max: 15, message: '长度在 15 个字符内', trigger: 'change' }
                ],
                maxScore: [
                    { required: true, message: '请输入最大值', trigger: 'blur' }
                    // { max: 15, message: '长度在 15 个字符内', trigger: 'change' }
                ],
                // symbol: [
                // { required: true, message: '请输入活动名称', trigger: 'blur' },
                // ]
            }
        };
    },
    methods: {
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        open() {
            this.ruleForm = {
                name: null,
                symbol: 1,
                secondSymbol: 1, // 第二个符号
                score: null,
                maxScore: null
            };
            this.dialogVisible = true;
        },
        // 确认新增
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    console.log(this.ruleForm);
                    this.ruleForm.gaugeId = sessionStorage.getItem('gaugeId');

                    const res = await addProject(JSON.stringify(this.ruleForm))
                    console.log(res);
                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            message: '新增成功',
                            type: 'success'
                        });
                        this.$emit('getProjects');
                    } else {
                        this.$message({
                            showClose: true,
                            message: '新增失败' + res.msg,
                            type: 'error'
                        });
                    }
                    this.dialogVisible = false;
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    }
};
</script>

<style lang="scss" scoped>
.el-select {
    width: 100%;
}

.scoreLimit {
    display: flex;
    justify-content: space-between;

    // .el-select,
    // .el-input {
    //     width: 250px;
    // }
}
.scoreLimit {
            justify-content: space-between;

            .el-input {
                width: 110px;
            }

            .el-select {
                width: 90px;
            }
        }
</style>