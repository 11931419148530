<template>
    <div class="type">
        <!-- 量表类别管理 -->
        <el-dialog title="量表类别" :visible.sync="dialogVisible" width="30%" :show-close="false">
            <div class="table">
                <el-table :data="tableData" style="width: 100%" height="300" size="small">
                    <el-table-column label="量表类别名称">
                        <template slot-scope="scope">
                            <!-- <span v-if="scope.row.isEdit === false"> {{scope.row.name}}</span> -->
                            <el-input placeholder="请输入量表类别名称" v-model="scope.row.name" size="small" maxlength="15"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <div class="btn">
                                <span @click="editType(scope.row)">保存</span>
                                <!-- <span v-if="scope.row.isEdit === false" @click="changeEdit(scope.row)">编辑</span>
                                <span v-if="scope.row.isEdit === true" @click="editType(scope.row)">保存</span> -->
                                <span @click="deleteType(scope.row.id)">删除</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="typeBtn">
                <el-button size="small" @click="cancel">关闭</el-button>
                <el-button type="primary" size="small" @click="addType">新增</el-button>
            </div>
            <!-- <span>这是一段信息</span> -->
            <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span> -->
        </el-dialog>
    </div>
</template>

<script>
import { getGaugeType, addGaugeType, deleteGaugeType, updateGaugeType } from '@/api/gauge/gaugeIntroduction.js'
export default {
    data() {
        return {
            dialogVisible: false,
            tableData: [{
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1517 弄'
            }, {
                date: '2016-05-01',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1519 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }]
        };
    },
    methods: {
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        // 打开当前模态框
        openType() {
            this.getGaugeTypeList();
            this.dialogVisible = true;
        },
        // 获取量表类型列表
        async getGaugeTypeList() {
            const res = await getGaugeType();
            console.log(res);
            if (res.code === 200) {
                this.tableData = res.data;
                // 给每条数据添加一个状态
                // this.tableData.forEach((item) => {
                //     item.isEdit = false
                // });
            }
        },
        // 点击取消
        cancel() {
            this.$emit('getGaugeTypeList')
            this.dialogVisible = false;
        },
        // 点击新增
        addType() {
            this.$prompt('请输入新增量表类别名称', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: false
            }).then(async ({ value }) => {
                const res = await addGaugeType({ name: value })
                console.log(res);
                if (res.code === 200) {
                    this.$message({
                        showClose: true,
                        type: 'success',
                        message: '新增成功'
                    });
                }
                this.getGaugeTypeList()
            }).catch(() => {
                this.$message({
                    showClose: true,
                    type: 'info',
                    message: '取消新增量表类别'
                });
            });
        },
        // 点击保存
        async editType(val) {
            const res = await updateGaugeType({ id: val.id, name: val.name });
            console.log(res);
            if (res.code === 200) {
                this.$message({
                    showClose: true,
                    type: 'success',
                    message: '修改成功'
                });
                this.getGaugeTypeList();
            } else {
                this.$message({
                    showClose: true,
                    type: 'error',
                    message: '修改失败'
                });
                this.getGaugeTypeList();
            }
            // console.log(val)
        },
        // 点击编辑
        changeEdit(val) {
            // if (val.isEdit )
            console.log(val)
            val.isEdit = true;
            // this.$forceUpdate();
            // console.log(val)
            console.log(this.tableData);
            // this.tableData.forEach(item => {
            //     if (item.id === val.id) {
            //         item.isEdit = true;
            //         // return item;
            //         // console.log(item)
            //     }
            //     // return item
            // })
            this.$forceUpdate();
        },
        // 点击删除
        async deleteType(val) {
            console.log(val)
            this.$confirm('确认删除该量表类别?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const res = await deleteGaugeType({ id: val })
                console.log(res);
                if (res.code === 200) {
                    this.$message({
                        showClose: true,
                        type: 'success',
                        message: '删除成功'
                    });
                    this.getGaugeTypeList();
                } else {
                    this.$message({
                        showClose: true,
                        type: 'error',
                        message: '删除失败'
                    });
                }
            }).catch(() => {
                this.$message({
                    showClose: true,
                    type: 'info',
                    message: '已取消删除'
                });
            });


        }
    }

}
</script>

<style lang="scss" scoped>
.type {
    ::v-deep.el-dialog {
        height: 400px;
        margin-top: 20vh !important;

        // padding: 0 20px;
        .el-dialog__header {
            text-align: center;

        }

        .el-dialog__title {
            font-size: 16px;
        }

        .el-dialog__body {
            padding: 0 20px;
        }
        .el-table{
                td{
                    padding: 2px 0px !important;
                }
            }
        .table {
            width: 100%;
            

            .btn {
                span {
                    display: inline-block;
                    margin: 0 5px;
                    cursor: pointer;
                    
                }
                span:hover{
                        color:  var(--custom-color);
                    }
            }
        }

        .typeBtn {
            padding-top: 6px;
            display: flex;
            align-items: center;
            justify-content: center;

            .el-button {
                margin: 0 20px;
            }
        }
    }
}
</style>