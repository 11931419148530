<template>
  <div class="list">
    <!-- 量表列表 -->
    <div class="searchBtn">
      <el-input size="small" placeholder="请输入量表名称" clearable style="width: 150px" v-model="searchInfo.name"></el-input>
      <el-select size="small" width="150px" v-model="searchInfo.gaugeTypeId" placeholder="请选择量表类别">
        <el-option v-for="item in gaugeTypeList" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <el-cascader :options="OrganizationsList" v-model="searchInfo.departmentId" size="small" placeholder="请选择组织机构"
        :show-all-levels="false" :props="{ value: 'id', label: 'name', checkStrictly: true, emitPath: false }">
      </el-cascader>
      <!-- <el-select size="small"  v-model="searchInfo.departmentId" placeholder="请选择所属部门">
        <el-option label="部门一" :value="1"></el-option>
        <el-option label="部门二" :value="2">
        </el-option>
      </el-select> -->
      <el-select v-model="searchInfo.status" placeholder="请选择状态" size="small">
      <el-option label="全部" :value="3"></el-option>
        <el-option label="草稿" :value="0"></el-option>
        <el-option label="未完成" :value="1"></el-option>
        <el-option label="已发布" :value="2"></el-option>
        
      </el-select>
      <el-button type="primary" size="small" @click="searchGauge">搜索</el-button>
      <el-button type="primary" @click="addGauge" size="small">新增</el-button>
    </div>
    <div class="tableContent">
      <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="id" label="ID"  width="80" fixed>
        </el-table-column>
        <el-table-column prop="name" label="量表名称" width="320" show-overflow-tooltip  fixed>
        </el-table-column>
        <el-table-column prop="showName" label="显示名称" width="250">
        </el-table-column >
        <el-table-column prop="gaugeTypeName" label="量表类别" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="people" label="年龄范围" width="110">
          <template slot-scope="scope">
            <span>{{ scope.row.minAge }}</span>
            <span v-if="scope.row.maxAge && scope.row.minAge">-</span>
            <span v-if="!scope.row.maxAge && scope.row.minAge">岁以上</span>
            <span>{{ scope.row.maxAge }}</span>
            <span v-if="!scope.row.minAge && scope.row.maxAge">岁以下</span></template>
        </el-table-column>
        <el-table-column prop="departmentName" label="所属机构" width="220" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="isWarning" label="预警" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.isWarning === 0">否</span>
            <span v-else-if="scope.row.isWarning === 1">是</span>
          </template>
        </el-table-column>
        <el-table-column prop="isReport" label="团体报告" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.isReport === 0">否</span>
            <span v-else-if="scope.row.isReport === 1">是</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0">草稿</span>
            <span v-else-if="scope.row.status === 1">未完成</span>
            <span v-else-if="scope.row.status === 2">已发布</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="160">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime}}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="preview(scope.row)">预览</el-button>
            <el-button type="text" size="small" @click="editGauge(scope.row.id)">编辑</el-button>
            <el-button type="text" size="small" @click="deleteGauge(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paging">
      <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
        layout="total, prev, pager, next, jumper" :total="paging.total">
      </el-pagination>
    </div>
    <PreviewGaugeVue ref="child"></PreviewGaugeVue>
  </div>
</template>

<script>
import { getGaugeList, deleteGauge } from '@/api/gauge/gaugeList.js'
import { getGaugeType } from '@/api/gauge/gaugeIntroduction.js'
import PreviewGaugeVue from './PreviewGauge.vue';
import { getOrganizationList } from '@/api/system/organization'
export default {
  data() {
    return {
      options: [
      ],
      OrganizationsList: [],
      // 搜索信息
      searchInfo: {
        name: '',
        gaugeTypeId: '',
        departmentId: '',
        status: ''
      },
      // 列表
      tableData: [],
      // 分页信息
      paging: {
        p: 1,
        ps: 20,
        total: 0
      },
      gaugeTypeList: []
    };
  },
  methods: {
    // 获取组织机构列表
    async getOrganizationsList() {
      const res = await getOrganizationList()
      console.log('组织机构');
      console.log(res);
      if (res.code === 200) {
        this.OrganizationsList = res.data;
        this.OrganizationsList = [{ id: -1, name: '全部' }, ...this.OrganizationsList];
        // this.deleteNullChildren(this.OrganizationsList)
      }
    },
    // 使用递归取出children为空的数据
    deleteNullChildren(val) {
      console.log(val);
      if (val) {
        val.forEach(item => {
          if (item.children.length < 1) {
            delete item.children;
          }
          this.deleteNullChildren(item.children)
        });
      }
    },
    // 获取量表类别
    async getGaugeTypeList() {
      const res = await getGaugeType();
      console.log(res);
      if (res.code === 200) {
        this.gaugeTypeList = res.data;
        this.gaugeTypeList= [{id: -1, name: '全部'},...this.gaugeTypeList]
      }
    },
    // 切换分页
    handleCurrentChange(val) {
      this.paging.p = val;
      this.getList()
    },
    // 跳转到新增
    addGauge() {
      sessionStorage.setItem('gaugeId', null);
      this.$router.push("/home/addGauge/scaleIntroduction");
    },
    // 跳转到编辑
    editGauge(val) {
      sessionStorage.setItem('gaugeId', val);
      this.$router.push("/home/addGauge/scaleIntroduction");
    },
    // 获取量表列表
    async getList() {
      let info = JSON.parse(JSON.stringify(this.searchInfo));
             console.log(info);
            if (info.status===3) {
                info.status = null;
            }
            if(info.departmentId === -1){
                info.departmentId = null;
            }
            if(info.gaugeTypeId === -1){
                info.gaugeTypeId = null;
            }
      const res = await getGaugeList({ p: this.paging.p, ps: this.paging.ps, ...info })
      console.log(res);
      if (res.code === 200) {
        this.tableData = res.data.records;
        this.paging.p = res.data.current;
        this.paging.ps = res.data.size;
        this.paging.total = res.data.total;
        console.log(this.tableData)
      }
    },
    // 点击搜索量表
    searchGauge() {
      this.paging.p = 1;
      this.getList();
    },
    // 删除量表
    async deleteGauge(val) {
      this.$confirm('删除该量表会删除该量表有关的所有报告，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await deleteGauge(val)
        console.log(res);
        if (res.code === 200) {
          this.$message({
            showClose: true,
            type: 'success',
            message: '删除成功!'
          });
          this.getList();
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '删除失败!' + res.msg
          });
        }
      }).catch(() => {
        this.$message({
          showClose: true,
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 点击预览量表
    preview(val) {
      this.$refs.child.openPreview(val.id)
    }
    
  },
  created() {
    this.getList();
    this.getGaugeTypeList();
    this.getOrganizationsList();
  },
  components: {
    PreviewGaugeVue
  }
};
</script>

<style lang="scss" scoped>
.list {
  // background-color: #E5F4FE;
  padding: 20px;
  // height: 100%;

  .searchBtn {
    padding-bottom: 20px;
    // display: flex;
    position: sticky;
    top: 0;
    // background-color: #E5F4FE;
    z-index: 5;

    // ::v-deep.el-input__inner {
    //   // height: 32px !important;
    //   // line-height: 32px !important;
    //   width: 150px !important;
    // }

    // .el-input,
    // .el-select, .el-cascader {
    //   margin-right: 30px;
    //   width: 150px;
    // }

    // .el-button {
    //   margin-left: 40px;
    // }
  }

  .tableContent {
    // margin-top: 25px;

    ::v-deep.el-table .el-table__body .el-table__cell {
            height: 32px !important;
            padding: 0px !important;
            line-height: 32px !important;
        }
         ::v-deep.el-table th.el-table__cell {
            height: 35px !important;
            padding: 0px !important;
            line-height: 35px !important;
        }
  }

  .paging {
    text-align: center;
    background-color: white;
    // height: 80px;

    // height: 30px;
    padding: 10px 0;
  }
}
</style>