<template>
  <div class="organization">
    <!-- 组织机构
       -->
    <div class="searchBtn">
      <el-button type="primary" size="small" @click="organizationAdd">新增</el-button>
    </div>
    <div class="tableList">
      <el-table :data="tableData" style="width: 100%;margin-bottom: 20px;" row-key="id" default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
        <el-table-column prop="id" label="ID">
        </el-table-column>
        <el-table-column prop="name" label="组织机构名称">
        </el-table-column>
        <el-table-column prop="userCount" label="机构人员数">
        </el-table-column>
        <!-- <el-table-column prop="address" label="地址">
        </el-table-column>
        <el-table-column prop="adminName" label="管理员姓名">
        </el-table-column>
        <el-table-column prop="adminPhone" label="管理员手机">
        </el-table-column>
        <el-table-column prop="adminEmail" label="管理员邮箱">
        </el-table-column> -->
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <div class="btn" v-if="(scope.row.isData === true)">
              <!-- <span>添加子级</span>
            <span @click="addMember(id)">添加人员</span> -->
            <el-button type="text" @click="toEdit(scope.row)" size="small">编辑</el-button>
            <el-button type="text" @click="DeleteOrganization(scope.row.id)" size="small">删除</el-button>
              <!-- <span @click="toEdit(scope.row)">编辑</span> -->
              <!-- <span @click="DeleteOrganization(scope.row.id)">删除</span> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <AddOrganizationVue ref="addChild" @getList="getList"></AddOrganizationVue>
    <EditOrganizationVue ref="editChild" @getList="getList"></EditOrganizationVue>
  </div>
</template>

<script>
import { getOrganizationList, deleteOrganization } from '@/api/system/organization'
import AddOrganizationVue from './AddOrganization.vue';
import EditOrganizationVue from './EditOrganization.vue';
export default {
  data() {
    return {
      tableData: []
    }
  },
  methods: {
    // 获取组织机构列表
    async getList() {
      const res = await getOrganizationList()
      console.log(res);
      if (res.code === 200) {
        this.tableData = res.data;
      }
    },
    // 新增组织机构
    organizationAdd() {
      this.$refs.addChild.openAdd();
    },
    // 点击编辑
    toEdit(val) {
      console.log(val);
      this.$refs.editChild.openEdit(val.id);
    },
    // 删除组织机构
    DeleteOrganization(val) {
      console.log(val);
      this.$confirm('确认删除该组织机构?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        console.log(11)
        const res = await deleteOrganization({ id: val })
        console.log(res);
        if (res.code === 200) {
          this.$message({
            type: 'success',
            showClose: true,
            message: '删除成功!'
          });
          this.getList();
        } else {
          this.$message({
            type: 'error',
            showClose: true,
            message: '删除失败!' + res.msg
          });
        }

      }).catch(() => {
        this.$message({
          type: 'info',
          showClose: true,
          message: '已取消删除'
        });
      });

    }
  },
  created() {
    this.getList();
  },
  components: { AddOrganizationVue, EditOrganizationVue }
}
</script>

<style lang="scss" scoped>
.organization {
  padding: 20px 20px;
  // .searchBtn{

  // }
  .tableList {
    margin-top: 20px;

    .btn {
      span {
        display: inline-block;
        margin-right: 20px;
      }
    }
    ::v-deep.el-table .el-table__body .el-table__cell {
            height: 32px !important;
            padding: 0px !important;
            line-height: 32px !important;
        }
         ::v-deep.el-table th.el-table__cell {
            height: 35px !important;
            padding: 0px !important;
            line-height: 35px !important;
        }
  }

}
</style>