<template>
    <!-- 结果解释 -->
    <div class="normalSetting">
        <div class="searchBtn searchBtn2">
            <!-- <p>维度名称</p> -->
            <el-select v-model="value" clearable placeholder="请选择维度名称" size="small" @change="changeDimension">
                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <el-button type="primary" size="small" @click="addNormal(1)">新增结果解释</el-button>
        </div>
        <div class="searchBtn searchBtn2">
            <!-- <p>维度名称</p> -->
            <el-select v-model="projectId" clearable placeholder="请选择单题计数名称" size="small" @change="changeDimension2">
                <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <el-button type="primary" size="small" @click="addNormal(2)">新增结果解释</el-button>
        </div>
        <div class="content">
            <!-- <div class="btn">
                <span>常模</span>
                <el-button type="primary" size="small" @click="addNormal">新增常模</el-button>
            </div> -->
            <!-- 根据维度 -->
            <div class="list" v-if="tableData.length > 0">
                <el-table :data="tableData" style="width: 100%" border>
                    <el-table-column prop="name" label="名称" width="150">
                    </el-table-column>
                    <el-table-column label="年龄" width="120">
                        <template slot-scope="scope">
                            <span>{{ scope.row.minAge }}</span>
                            <span v-if="scope.row.maxAge !== null && scope.row.minAge !== null">-</span>
                            <span v-if="scope.row.maxAge === null && scope.row.minAge !== null">岁以上</span>
                            <span>{{ scope.row.maxAge }}</span>
                            <span v-if="scope.row.minAge === null && scope.row.maxAge !== null">岁以下</span>
                            <!-- <span>{{ scope.row.minAge }}</span>
                            <span>-</span>
                            <span>{{ scope.row.maxAge }}</span> -->
                        </template>
                    </el-table-column>
                    <el-table-column label="性别" width="80">
                        <template slot-scope="scope">
                            <span v-if="scope.row.sex === 1">男</span>
                            <span v-if="scope.row.sex === 2">女</span>
                            <span v-if="scope.row.sex === 3">全部</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="常模参考值" width="100" prop="reference">
                        <!-- <template slot-scope="scope">
                            <span v-if="scope.row.sex === 1">男</span>
                            <span v-if="scope.row.sex === 2">女</span>
                            <span v-if="scope.row.sex === 3">全部</span>
                        </template> -->
                    </el-table-column>
                    <el-table-column label="分值范围" width="120">
                        <template slot-scope="scope">
                            <span>{{ scope.row.minRange }}</span>
                            <span v-if="scope.row.firstSymbol === 1">＜</span>
                            <span v-if="scope.row.firstSymbol === 2">≤</span>
                            <span v-if="scope.row.firstSymbol === 3">＞</span>
                            <span v-if="scope.row.firstSymbol === 4">≥</span>
                            <span>X</span>
                            <span v-if="scope.row.secondSymbol === 1">＜</span>
                            <span v-if="scope.row.secondSymbol === 2">≤</span>
                            <span v-if="scope.row.secondSymbol === 3">＞</span>
                            <span v-if="scope.row.secondSymbol === 4">≥</span>
                            <span>{{ scope.row.maxRange }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="预警" width="100">
                        <template slot-scope="scope">
                            <span v-if="scope.row.level === 0">无</span>
                            <span v-if="scope.row.level === 1">一级</span>
                            <span v-if="scope.row.level === 2">二级</span>
                            <span v-if="scope.row.level === 3">三级</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="程度" width="100" prop="degree">

                    </el-table-column>
                    <!-- show-overflow-tooltip="true" -->
                    <el-table-column label="结果解释">
                        <template slot-scope="scope">
                            <!-- <el-tooltip effect="light" placement="top">
                                <p slot="content" style="width:500px"> {{ scope.row.memo }}</p>
                                <span class="textContent">{{ scope.row.memo }}</span>
                            </el-tooltip> -->
                            <span class="textContent">{{ scope.row.memo }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="100">
                        <template slot="header" slot-scope="scope">
                            <span>是否显示</span>
                            <el-tooltip class="item" effect="dark" content="结果解释是否在报告中显示" placement="top">
                                <i class="el-icon-question"></i>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">
                            <el-switch @change="changeShow(scope.row)" style="display: block; height:22px"
                                v-model="scope.row.memoIsShow">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="辅导建议">
                        <template slot-scope="scope">

                            <!-- <el-tooltip effect="light" placement="top">
                                <p slot="content" style="width:500px"> {{ scope.row.proposal }}</p>
                                <span class="textContent">{{ scope.row.proposal }}</span>
                            </el-tooltip> -->
                            <span class="textContent">{{ scope.row.proposal }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="100">
                        <template slot="header" slot-scope="scope">
                            <span>是否显示</span>
                            <el-tooltip class="item" effect="dark" content="辅导建议是否在报告中显示" placement="top">
                                <i class="el-icon-question"></i>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">
                            <el-switch @change="changeShow(scope.row)" style="display: block; height:22px"
                                v-model="scope.row.proposalIsShow">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="EditNormal(scope.row.id, 1)">编辑</el-button>
                            <el-button type="text" size="small" @click="deleteNormal(scope.row.id, 1)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 根据单题计数 -->
            <div class="list" v-if="projectData.length > 0" style="margin-top: 20px">
                <el-table :data="projectData" style="width: 100%" border>
                    <el-table-column prop="name" label="名称" width="150">
                    </el-table-column>
                    <el-table-column label="年龄" width="120">
                        <template slot-scope="scope">
                            <span>{{ scope.row.minAge }}</span>
                            <span v-if="scope.row.maxAge !== null && scope.row.minAge !== null">-</span>
                            <span v-if="scope.row.maxAge === null && scope.row.minAge !== null">岁以上</span>
                            <span>{{ scope.row.maxAge }}</span>
                            <span v-if="scope.row.minAge === null && scope.row.maxAge !== null">岁以下</span>
                            <!-- <span>{{ scope.row.minAge }}</span>
                            <span>-</span>
                            <span>{{ scope.row.maxAge }}</span> -->
                        </template>
                    </el-table-column>
                    <el-table-column label="性别" width="80">
                        <template slot-scope="scope">
                            <span v-if="scope.row.sex === 1">男</span>
                            <span v-if="scope.row.sex === 2">女</span>
                            <span v-if="scope.row.sex === 3">全部</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="常模参考值" width="100" prop="reference">
                        <!-- <template slot-scope="scope">
                            <span v-if="scope.row.sex === 1">男</span>
                            <span v-if="scope.row.sex === 2">女</span>
                            <span v-if="scope.row.sex === 3">全部</span>
                        </template> -->
                    </el-table-column>
                    <el-table-column label="计数范围" width="120">
                        <template slot-scope="scope">
                            <span>{{ scope.row.countSymbol }}</span>
                            <!-- <span v-if="scope.row.countSymbol === 1">＞</span>
                            <span v-if="scope.row.countSymbol === 2">≥</span>
                            <span v-if="scope.row.countSymbol === 3">＜</span>
                            <span v-if="scope.row.countSymbol === 4">≤</span>
                            <span v-if="scope.row.countSymbol === 5">=</span>
                            <span v-if="scope.row.countSymbol === 6">范围内个数最多&nbsp;&nbsp;&nbsp;</span>
                            <span v-if="scope.row.countSymbol === 7">范围内个数最少&nbsp;&nbsp;&nbsp;</span>
                            <span v-if="(scope.row.countSymbol === 8)">所有答案中个数最多&nbsp;&nbsp;&nbsp;</span>
                            <span v-if="(scope.row.countSymbol === 9)">所有答案中个数最少&nbsp;&nbsp;&nbsp;</span> -->

                            <span>{{ scope.row.count }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="预警" width="100">
                        <template slot-scope="scope">
                            <span v-if="scope.row.level === 0">无</span>
                            <span v-if="scope.row.level === 1">一级</span>
                            <span v-if="scope.row.level === 2">二级</span>
                            <span v-if="scope.row.level === 3">三级</span>
                        </template>
                    </el-table-column>
                    <!-- show-overflow-tooltip="true" -->
                    <el-table-column label="结果解释">
                        <template slot-scope="scope">
                            <!-- <el-tooltip effect="light" placement="top">
                                <p slot="content" style="width:500px"> {{ scope.row.memo }}</p>
                                <span class="textContent">{{ scope.row.memo }}</span>
                            </el-tooltip> -->
                            <span class="textContent">{{ scope.row.memo }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="100">
                        <template slot="header" slot-scope="scope">
                            <span>是否显示</span>
                            <el-tooltip class="item" effect="dark" content="结果解释是否在报告中显示" placement="top">
                                <i class="el-icon-question"></i>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">
                            <el-switch @change="changeShow2(scope.row)" style="display: block; height:22px"
                                v-model="scope.row.memoIsShow" >
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="辅导建议">
                        <template slot-scope="scope">

                            <!-- <el-tooltip effect="light" placement="top">
                                <p slot="content" style="width:500px"> {{ scope.row.proposal }}</p>
                                <span class="textContent">{{ scope.row.proposal }}</span>
                            </el-tooltip> -->
                            <span class="textContent">{{ scope.row.proposal }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="100">
                        <template slot="header" slot-scope="scope">
                            <span>是否显示</span>
                            <el-tooltip class="item" effect="dark" content="辅导建议是否在报告中显示" placement="top">
                                <i class="el-icon-question"></i>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">
                            <el-switch @change="changeShow2(scope.row)" style="display: block; height:22px"
                                v-model="scope.row.proposalIsShow" >
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="EditNormal(scope.row.id, 2)">编辑</el-button>
                            <el-button type="text" size="small" @click="deleteNormal(scope.row.id, 2)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <AddNormal ref="child" @changeDimension="changeDimension"></AddNormal>
        <AddSubjectVue ref="addSubjectVue" @changeDimension="changeDimension2"></AddSubjectVue>
        <EditNormalVue ref="childEdit" @changeDimension="changeDimension"></EditNormalVue>
        <EditSubjectVue ref="SubjectVueEdit" @changeDimension="changeDimension2"></EditSubjectVue>
    </div>
</template>
<script>
import AddNormal from './AddNormal.vue'
import AddSubjectVue from './AddSubject.vue'
import EditNormalVue from './EditNormal.vue'
import EditSubjectVue from './EditSubject.vue'
import { getDimensionList, getProjectList } from '@/api/gauge/dimension'
import { getNormalList, getProjectNormalList, normalDelete, updateNormal, updateProjectNormal, projectDelete } from '@/api/gauge/normal'
export default {
    components: { AddNormal, EditNormalVue, AddSubjectVue, EditSubjectVue },
    data() {
        return {
            options: [],
            value: '', // 选择框目前选中的值
            tableData: [],
            projectData: [], //单题计数列表
            projectList: [],
            projectId: null, // 选中的单题计数
        }
    },
    methods: {
        // 点击添加常模
        addNormal(val) {
            if (sessionStorage.getItem('gaugeId') === 'null') {
                // console.log(1111)
                this.$message({
                    showClose: true,
                    message: '请先确认量表介绍',
                    type: 'info'
                });
            } else {
                if (val === 1) {
                    if (this.value === '') {
                        this.$message({
                            showClose: true,
                            message: '请先选择对应的维度'
                        });
                    } else {
                        this.$refs.child.openAddNormal(this.value);
                    }
                    
                } else {
                    if (this.projectId === null) {
                        this.$message({
                            showClose: true,
                            message: '请先选择对应的单题计数'
                        });
                    } else {
                        this.$refs.addSubjectVue.openAddNormal(this.projectId);
                    }
                }
                // if (this.value === '' && this.projectId === null) {
                //     this.$message({
                //         showClose: true,
                //         message: '请先选择对应的维度'
                //     });
                // } else {
                //     if (val === 1) {
                //         this.$refs.child.openAddNormal(this.value);
                //     } else {
                //         this.$refs.addSubjectVue.openAddNormal(this.projectId);
                //     }
                // }
            }
        },

        // 获取维度列表
        async getDimension() {
            const res = await getDimensionList({ id: sessionStorage.getItem('gaugeId') });
            console.log(res)
            if (res.code === 200) {
                this.options = res.data;
            }
        },
        // 选择框发生改变
        async changeDimension() {
            console.log(this.value)
            if (this.value !== '') {
                const res = await getNormalList({ id: this.value })
                console.log(res)
                if (res.code === 200) {
                    this.tableData = res.data
                }
            } else {
                this.tableData = []
            }
        },
        // 选择框发生改变 -获取单题计数列表
        async changeDimension2() {
            console.log(typeof(this.value))
            if (this.projectId === null || this.projectId === '') {
                this.projectData = []
            } else {
                
                const res = await getProjectNormalList(this.projectId)
                console.log(res)
                if (res.code === 200) {
                    // this.projectData = res.data;
                    this.projectData = this.$utils.ToType(res.data)
                }
            }
        },
        // 查看编辑常模
        EditNormal(val, val2) {
            console.log(val)

            if (val2 === 1) {
                this.$refs.childEdit.openEditNormal(this.value, val);
            } else {
                this.$refs.SubjectVueEdit.openEditNormal(this.projectId, val);
            }
        },
        // 修改控制显示
        async changeShow(val) {
            console.log(val)
            delete val.createTime;
            const res = await updateNormal(JSON.stringify(val))
            console.log(res);
            if (res.code === 200) {
                this.getDimension();
            } else {
                this.getDimension();
                this.$message({
                    showClose: true,
                    // type: 'success',
                    message: '操作失败!' + res.msg
                });
            }
        },
        // 修改控制显示-单题计数
        async changeShow2(val) {
            console.log(val)
            delete val.createTime;
            const res = await updateProjectNormal(val.id, JSON.stringify(val))
            console.log(res);
            if (res.code === 200) {
                this.getDimension();
            } else {
                this.getDimension();
                this.$message({
                    showClose: true,
                    // type: 'success',
                    message: '操作失败!' + res.msg
                });
            }
        },
        // 删除常模
        async deleteNormal(val, val2) {
            console.log(val)
            this.$confirm('确认删除该结果解释?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const res = null;
                if (val2 === 1) {
                    const res = await normalDelete({ id: val })
                    console.log(res)
                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            type: 'success',
                            message: '删除成功!'
                        });
                        // console.log(111)
                        this.changeDimension();
                    } else {
                        this.$message({
                            showClose: true,
                            type: 'error',
                            message: '删除失败!' + res.msg
                        });
                    }
                } else {
                    const res = await projectDelete([val])
                    console.log(res)
                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            type: 'success',
                            message: '删除成功!'
                        });
                        // console.log(111)
                        this.changeDimension2();
                    } else {
                        this.$message({
                            showClose: true,
                            type: 'error',
                            message: '删除失败!' + res.msg
                        });
                    }
                }


            }).catch(() => {
                this.$message({
                    showClose: true,
                    type: 'info',
                    message: '已取消删除'
                });
            });
            // const res = await normalDelete({id: val})

        },
        // 获取单题计数列表
        async getProjects() {
            // let gauge = Number(sessionStorage.getItem('gaugeId'))
            const res = await getProjectList(sessionStorage.getItem('gaugeId'));
            console.log(res);
            if (res.code === 200) {
                this.projectList = res.data;
            }

        },
    },
    created() {
        this.getDimension();
        this.getProjects();
    }
}
</script>
<style lang="scss" scoped>
.normalSetting {
    .searchBtn {
        margin-top: 30px;
    }

    .searchBtn2 {
        margin-top: 10px;

        ::v-deep .el-select .el-input {
            width: 170px !important;
        }
    }

    // .textContent {
    //     display: inline-block;
    //     white-space: nowrap;
    //     width: 100%;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //     // // text-overflow: -o-ellipsis-lastline;
    //     // overflow: hidden; // 溢出隐藏
    //     // text-overflow: ellipsis; // 超出显示...
    //     // // display: -webkit-box;
    //     // -webkit-line-clamp: 2;
    //     // -webkit-box-orient: vertical; // 超出多少行显示...
    // }

    ::v-deep.el-table .el-table__cell {
        padding: 5px 0;
    }

    .content {
        margin-top: 30px;
        font-size: 14px;


        .btn {
            .el-button {
                margin-left: 20px;
            }
        }

    }
}
</style>