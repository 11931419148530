<template>
    <!-- 编辑结果解释 -->
    <div class="addNormal">
        <el-dialog title="编辑结果解释" :visible.sync="dialogVisible" width="620px" :show-close="false">
            <div class="tableForm">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" size="small">
                    <el-form-item prop="name">
                        <span>结果名称</span>
                        <br>
                        <el-input v-model="ruleForm.name" placeholder="仅限输入15个字符"></el-input>
                    </el-form-item>
                    <el-form-item >
                        <span>结果参考值</span>
                        <br/>
                        <el-input v-model="ruleForm.reference" placeholder="请输入结果参考值"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span>性别</span>
                        <br>
                        <el-select v-model="ruleForm.sex" placeholder="请选择性别">
                            <el-option label="男" :value="1"></el-option>
                            <el-option label="女" :value="2"></el-option>
                            <el-option label="全部" :value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <span>年龄</span>
                        <div class="ageLimit">
                            <!-- <el-input v-model="ruleForm.minAge" placeholder="请输入最小年龄"></el-input>
                            <span>至</span>
                            <el-input v-model="ruleForm.maxAge" placeholder="请输入最大年龄"></el-input> -->
                            <el-form-item >
                                <el-input v-model="ruleForm.minAge" placeholder="请输入最小年龄"></el-input>
                            </el-form-item>
                            <span>至</span>
                            <el-form-item >
                                <el-input v-model="ruleForm.maxAge" placeholder="请输入最大年龄"></el-input>
                            </el-form-item>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <span>预警等级</span>
                        <br>
                        <el-select v-model="ruleForm.level" placeholder="请选择等级">
                            <el-option label="无" :value="0"></el-option>
                            <el-option label="一级" :value="1"></el-option>
                            <el-option label="二级" :value="2"></el-option>
                            <el-option label="三级" :value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <span>分值范围</span>
                        <div class="scoreLimit">
                            <el-form-item prop="minRange">
                                <el-input v-model="ruleForm.minRange" placeholder="请输入最小分值"></el-input>
                            </el-form-item>
                            <el-select v-model="ruleForm.firstSymbol" placeholder="请选择">
                                <el-option label="<" :value="1"></el-option>
                                <el-option label="≤" :value="2"></el-option>
                                <!-- <el-option label=">" :value="3"></el-option>
                                <el-option label="≥" :value="4"></el-option> -->
                            </el-select>
                            <span>分数</span>
                            <el-select v-model="ruleForm.secondSymbol" placeholder="请选择">
                                <el-option label="<" :value="1"></el-option>
                                <el-option label="≤" :value="2"></el-option>
                                <!-- <el-option label=">" :value="3"></el-option>
                                <el-option label="≥" :value="4"></el-option> -->
                            </el-select>
                            <el-form-item prop="maxRange">
                                <el-input v-model="ruleForm.maxRange" placeholder="请输入最大分值"></el-input>
                            </el-form-item>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <span>程度</span>
                        <br>
                        <el-select v-model="ruleForm.degree" placeholder="请选择结果程度">
                            <el-option label="正常" value="正常"></el-option>
                            <el-option label="轻度" value="轻度"></el-option>
                            <el-option label="中度" value="中度"></el-option>
                            <el-option label="偏重" value="偏重"></el-option>
                            <el-option label="重度" value="重度"></el-option>
                        </el-select>
                    </el-form-item>
                     <!-- <el-select v-model="ruleForm.degree" placeholder="请选择结果程度">
                            <el-option label="轻度" value="轻度"></el-option>
                            <el-option label="中度" value="中度"></el-option>
                            <el-option label="重度" value="重度"></el-option>
                        </el-select> -->
                    <el-form-item prop="memo">
                        <span>结果解释</span>
                        <br>
                        <el-input type="textarea" autosize placeholder="请输入分值说明（限制500字以内）" v-model="ruleForm.memo">
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="proposal">
                        <span>辅导建议</span>
                        <br>
                        <el-input type="textarea" autosize placeholder="请输入辅导建议（限制500字以内）" v-model="ruleForm.proposal">
                        </el-input>
                    </el-form-item>
                    <!-- <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
                    </el-form-item> -->
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')" size="small">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { updateNormal, normalDetail } from '@/api/gauge/normal'
export default {
    data() {
        return {
            dialogVisible: false,
            textarea1: '',
            textarea2: '',
            ruleForm: {
                dimensionId: null, // 维度id
                firstSymbol: 1, // 第一个符号
                level: 0, //预警等级
                maxAge: 0, // 最大年龄
                maxRange: 0, // 最大分值
                memo: '', // 分值说明
                minAge: 0, // 最小年龄
                minRange: 0, // 最小分值
                name: '', // 结果名称
                proposal: '', // 指导建议
                secondSymbol: 1, // 第二个符号
                sex: 3, // 性别
                id: null,
                reference: null,
                degree: null //程度
            },
            rules: {
                name: [
                    { required: true, message: '请输入结果名称', trigger: 'blur' },
                    { min: 1, max: 15, message: '长度在 1 到 15 个字符', trigger: 'blur' }
                ],
                minAge: [
                    { required: true, message: '请输入最小年龄', trigger: 'blur' }
                ],
                maxAge: [
                    { required: true, message: '请输入最大年龄', trigger: 'blur' }
                ],
                minRange: [
                    { required: true, message: '请输入最小分值', trigger: 'blur' }
                ],
                maxRange: [
                    { required: true, message: '请输入最大分值', trigger: 'blur' }
                ],
                proposal: [
                    { max: 500, message: '长度在500字以内', trigger: 'change' }
                ],
                memo: [
                    { max: 500, message: '长度在500字以内', trigger: 'change' }
                ]
            },
            normalId: null //当前结果id
        };
    },
    methods: {
        // 控制模态框
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        // 打开当前模态框
        openEditNormal(val, id) {
            console.log('传过来的', id)
            this.normalId = id;
            this.getNormalDetail()
            // this.ruleForm.dimensionId = val;
            this.dialogVisible = true;
        },
        // 提交
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    // alert('submit!');
                    console.log(this.ruleForm)
                    const res = await updateNormal(JSON.stringify(this.ruleForm))
                    console.log(res)
                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            message: '修改成功！',
                            type: 'success'
                        });
                        this.$emit('changeDimension')
                        this.dialogVisible = false;
                    } else {
                        this.$message({
                            showClose: true,
                            message: '修改失败！' + res.msg,
                            type: 'error'
                        });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        async getNormalDetail() {
            const res = await normalDetail({ id: this.normalId })
            console.log(res)
            if (res.code === 200) {
                this.ruleForm = res.data;
                delete this.ruleForm.createTime;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.addNormal {
    
    ::v-deep.el-dialog {
        margin-top: 10vh !important;
        margin-bottom: 0px !important;
        height: 75%;
        overflow-y: scroll;
        // position: relative;
    }

    // 设置模态框的样式
    ::v-deep.el-dialog__header {
        text-align: center;
        background-color: white;
        z-index: 2;
        position: sticky;
        top: 0;
    }

    ::v-deep.el-dialog__footer {
        text-align: center;
        background-color: white;
        z-index: 2;
        position: sticky;
        bottom: 0;

        .el-button {
            margin: 0 30px;
        }
    }

    ::v-deep.el-dialog__body {
        padding: 0 !important;
    }

    .tableForm {
        padding: 0 40px;

        .el-select {
            width: 100%;
        }

        // height: 80%;
        // overflow-y: scroll;
        .ageLimit,
        .scoreLimit {
            display: flex;
            height: 32px;

            span {
                display: inline-block;
                margin: 0 20px;
            }
        }

        .scoreLimit {
            justify-content: space-between;
            height: 32px;

            .el-input {
                width: 110px;
            }

            .el-select {
                width: 90px;
            }
        }

        .el-form-item {
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 20px;
            }
        }
    }
}
</style>