// 常模设置
import request from '@/utils/request'
// 根据维度获取常模列表
export function getNormalList(params) {
  return request({
    url: '/gauge/norm/list',
    method: 'get',
    params
  });
}
// 根据单题记数获取常模列表
export function getProjectNormalList(gaugeProjectId,params) {
  return request({
    url: `/gauge/count/${gaugeProjectId}`,
    method: 'get',
    params
  });
}
// 新增常模
export function addNormal(data) {
  return request({
    url: '/gauge/save/norm',
    method: 'post',
    data
  });
}
// 新增常模-记数统计
export function addProjectNormal(data) {
  return request({
    url: '/gauge/projectCountDetail',
    method: 'post',
    data
  });
}
// 修改常模
export function updateNormal(data) {
  return request({
    url: '/gauge/update/norm',
    method: 'post',
    data
  });
}
// 修改常模 -单题计数
export function updateProjectNormal(countDetailId,data) {
  return request({
    url: `/gauge/countDetail/${countDetailId}`,
    method: 'put',
    data
  });
}
// 获取常模详情
export function normalDetail(params) {
  return request({
    url: '/gauge/norm',
    method: 'get',
    params
  });
}
// 获取常模详情 - 单题计数
export function projectDetail(countDetailId, params) {
  return request({
    url: `/gauge/countDetail/${countDetailId}`,
    method: 'get',
    params
  });
}
// 删除常模
export function normalDelete(params) {
  return request({
    url: '/gauge/norm',
    method: 'delete',
    params
  });
}
// 删除常模-单题计数
export function projectDelete(data) {
  return request({
    url: '/gauge/projectCountDetail',
    method: 'delete',
    data
  });
}