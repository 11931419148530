import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login/Login.vue'
import GaugeCategory from '@/views/gauge/gaugeCategory/GaugeCategory.vue'
import GaugeCombination from '@/views/gauge/gaugeCombination/GaugeCombination.vue'
import addGauge from '../views/gauge/gaugeCategory/AddGauge.vue'
import ScaleIntroduction from '@/views/gauge/gaugeCategory/introduction/ScaleIntroduction.vue'
import TitleEditor from '@/views/gauge/gaugeCategory/title/TitleEditor.vue'
import DimensionDivision from '@/views/gauge/gaugeCategory/dimension/DimensionDivision.vue'
import NormalSetting from '@/views/gauge/gaugeCategory/normal/NormalSetting.vue'
import AlertSetting from '@/views/gauge/gaugeCategory/earning/AlertSetting.vue'
import Organization from '../views/systemManagement/organization/Organization.vue'
// import ViewReport from'@/views/psychologicalEvaluation/result/ViewReport.vue'
import {
  Notification,
  Message
} from 'element-ui';
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import( /* webpackChunkName: "about" */ '../views/home/Home.vue'),
    // 前置导航守卫
    beforeEnter: async (to, from, next) => {
      // 获取本地的 token
      const token = sessionStorage.token;
      if (token) {
        // 本地有 token
        // const data = await api.users.getLogin({
        //   account: sessionStorage.account,
        //   password: sessionStorage.password,
        // });
        // if (data.code) {
        //   // 如果后端返回了用户信息，可以将用户信息保存在 Vuex 或本地存储中
        //   store.commit("SET_USER_INFO", data.data);
        //   // token 验证有效，允许用户进入权限页面
        next();
        // }
      } else {
        // 本地没有 token
        // ElementUI.Message({
        //   showClose: true,
        //   message: '这是一条消息提示'
        // });
        // Notification.error({
        //   title: '你还未登录，请先登录!',
        //   duration: 5000
        // });
        Message({
          showClose: true,
          message: '你还未登录，请先登录！'
        });
        // alert("你还未登录，请先登录"); // 可以根据项目的 UI 框架来设置弹框提示
        next("/");
      }
    },
    children: [
      // 量表列表
      {
        path: 'gaugeCategory',
        name: 'GaugeCategory',
        component: GaugeCategory,
        meta: {
          cache: true,
          title: "量表管理>量表列表",
          breadcrumbList: [{
            id: 1,
            name: '量表管理'
          }, {
            id: 2,
            name: '量表列表',
            path: '/home/gaugeCategory'
          }]
        }
      },
      // 新增量表
      {
        path: 'addGauge',
        name: 'AddGauge',
        component: addGauge,
        meta: {
          cache: true,
          title: "新增量表",
          breadcrumbList: [{
            id: 1,
            name: '量表管理'
          }, {
            id: 2,
            name: '量表列表',
            path: '/home/gaugeCategory'
          }, {
            id: 3,
            name: '新增量表',
            path: '/home/addGauge'
          }]
        },
        children: [
          // 量表介绍
          {
            path: 'scaleIntroduction',
            name: 'ScaleIntroduction',
            component: ScaleIntroduction,
            breadcrumbList: [{
              id: 1,
              name: '量表管理'
            }, {
              id: 2,
              name: '量表列表',
              path: '/home/gaugeCategory'
            }, {
              id: 3,
              name: '新增量表',
              path: '/home/addGauge'
            }]
          },
          // 题目编辑
          {
            path: 'titleEditor',
            name: 'TitleEditor',
            component: TitleEditor,
          },
          // 维度划分
          {
            path: 'dimensionDivision',
            name: 'DimensionDivision',
            component: DimensionDivision,
          },
          // 常模设置
          {
            path: 'normalSetting',
            name: 'NormalSetting',
            component: NormalSetting
          },
          // 预警设置
          {
            path: 'alertSetting',
            name: 'AlertSetting',
            component: AlertSetting
          }, {
            path: 'chartSetting',
            name: 'chartSetting',
            component: () => import('../views/gauge/gaugeCategory/chartSettings/ChartSettings.vue')
          }
        ]
      },
      // 量表组合
      {
        path: 'gaugeCombination',
        name: 'GaugeCombination',
        component: GaugeCombination,
        meta: {
          cache: true,
          title: "量表管理>量表组合",
          breadcrumbList: [{
            id: 1,
            name: '量表管理'
          }, {
            id: 2,
            name: '量表组合',
            path: '/home/gaugeCombination'
          }]
        }
      },
      // 系统管理 组织机构
      {
        path: 'organization',
        name: 'organization',
        component: Organization,
        meta: {
          cache: true,
          title: "系统管理",
          breadcrumbList: [{
            id: 1,
            name: '系统管理'
          }, {
            id: 2,
            name: '组织机构',
            path: '/home/organization'
          }]
        },
        children: []
      },
      // 用户管理
      {
        path: 'users',
        name: 'users',
        component: () => import('@/views/systemManagement/user/UserList.vue'),
        meta: {
          cache: true,
          title: "用户管理",
          breadcrumbList: [{
            id: 1,
            name: '系统管理'
          }, {
            id: 2,
            name: '用户管理',
            path: '/home/users'
          }]
        }
      },
      // 操作日志
      {
        path: 'logs',
        name: 'logs',
        component: () => import('@/views/systemManagement/log/OperationLog.vue'),
        meta: {
          cache: true,
          title: "操作日志",
          breadcrumbList: [{
            id: 1,
            name: '系统管理'
          }, {
            id: 2,
            name: '操作日志',
            path: '/home/logs'
          }]
        }
      },
      // 角色管理
      {
        path: 'role',
        name: 'role',
        component: () => import('@/views/systemManagement/role/RoleList.vue'),
        meta: {
          cache: true,
          title: "用户管理",
          breadcrumbList: [{
            id: 1,
            name: '系统管理'
          }, {
            id: 2,
            name: '权限管理',
            path: '/home/role'
          }]
        }
      },
      // 心理测评 测评计划
      {
        path: 'plan',
        name: 'plan',
        component: () => import('@/views/psychologicalEvaluation/plan/PlanList.vue'),
        meta: {
          cache: true,
          title: "心理测评",
          breadcrumbList: [{
            id: 1,
            name: '心理测评'
          }, {
            id: 2,
            name: '测评计划',
            path: '/home/plan'
          }]
        },
        children: []
      },
      // 心理测评 测评结果
      {
        path: 'result',
        name: 'result',
        component: () => import('@/views/psychologicalEvaluation/result/ResultList.vue'),
        meta: {
          cache: true,
          title: "测评结果",
          breadcrumbList: [{
            id: 1,
            name: '心理测评'
          }, {
            id: 2,
            name: '测评结果',
            path: '/home/result'
          }]
        },

      },
      // 查看报告
      {
        path: 'viewReport',
        name: 'viewReport',
        component: () => import('../views/psychologicalEvaluation/result/ViewReport.vue'),
        meta: {
          cache: true,
          title: "测评报告",
          breadcrumbList: [{
              id: 1,
              name: '心理测评'
            }, {
              id: 2,
              name: '测评结果',
              path: '/home/result'
            },
            {
              id: 2,
              name: '测评报告',
              path: '/home/viewReport'
            }
          ]
        }
      },
      // 预警报告
      {
        path: 'warningReport',
        name: 'warningReport',
        component: () => import('../views/dangerWarning/personalWarning/WarningReport.vue'),
        meta: {
          cache: true,
          title: "预警报告",
          breadcrumbList: [{
              id: 1,
              name: '危机预警'
            }, {
              id: 2,
              name: '预警情况',
              path: '/home/personalWarning'
            },
            {
              id: 3,
              name: '预警报告',
              path: '/home/warningReport'
            }
          ]
        }
      },
      // 答题记录
      {
        path: 'answerReport',
        name: 'answerReport',
        component: () => import('../views/dangerWarning/personalWarning/AnswerRecord.vue'),
        meta: {
          cache: true,
          title: "答题记录",
          breadcrumbList: [{
              id: 1,
              name: '危机预警'
            }, {
              id: 2,
              name: '预警情况',
              path: '/home/personalWarning'
            },
            {
              id: 3,
              name: '答题记录',
              path: '/home/answerReport'
            }
          ]
        }
      },
      // 测评报告
      {
        path: 'evaluationReport',
        name: 'evaluationReport',
        component: () => import('../views/dangerWarning/personalWarning/ViewReport.vue'),
        meta: {
          cache: true,
          title: "测评报告",
          breadcrumbList: [{
              id: 1,
              name: '危机预警'
            }, {
              id: 2,
              name: '预警情况',
              path: '/home/personalWarning'
            },
            {
              id: 3,
              name: '测评报告',
              path: '/home/evaluationReport'
            }
          ]
        }
      },
      // 心理测评 数据导入
      {
        path: 'importData',
        name: 'importData',
        component: () => import('@/views/psychologicalEvaluation/importData/ImportData.vue'),
        meta: {
          cache: true,
          title: "答题数据",
          breadcrumbList: [{
            id: 1,
            name: '心理测评'
          }, {
            id: 2,
            name: '答题数据',
            path: '/home/importData'
          }]
        },
      },
      {
        path: 'addPlan',
        name: 'addPlan',
        component: () => import('../views/psychologicalEvaluation/plan/AddPlan.vue'),
        meta: {
          cache: true,
          title: "新增测评计划",
          breadcrumbList: [{
              id: 1,
              name: '心理测评'
            }, {
              id: 2,
              name: '测评计划',
              path: '/home/addPlan'
            },
            {
              id: 3,
              name: '新增测评计划',
              path: '/home/addPlan'
            }
          ]
        },
        children: [{
            path: 'planOverview',
            name: 'PlanOverview',
            component: () => import('../views/psychologicalEvaluation/plan/planDetail/PlanDetail.vue')
          },
          {
            path: 'ParticipatingUsers',
            name: 'ParticipatingUsers',
            component: () => import('../views/psychologicalEvaluation/plan/ParticipatingUsers/ParticipatingUsers.vue')
          }
        ]
      },
      // 预警情况
      {
        path: 'personalWarning',
        name: 'personalWarning',
        component: () => import('../views/dangerWarning/personalWarning/PersonalWarning.vue'),
        meta: {
          cache: true,
          title: "危机预警",
          breadcrumbList: [{
            id: 1,
            name: '危机预警'
          }, {
            id: 2,
            name: '预警情况',
            path: '/home/personalWarning'
          }]
        }
      },
      // 预警统计
      {
        path: 'warningStatistics',
        name: 'warningStatistics',
        component: () => import('../views/dangerWarning/warningStatistics/WarningStatistics.vue'),
        meta: {
          cache: true,
          title: "危机预警",
          breadcrumbList: [{
            id: 1,
            name: '危机预警'
          }, {
            id: 2,
            name: '预警统计',
            path: '/home/warningStatistics'
          }]
        }
      },
      // 危机干预
      {
        path: 'crisisIntervention',
        name: 'crisisIntervention',
        component: () => import('../views/dangerWarning/crisisIntervention/CrisisIntervention.vue'),
        meta: {
          cache: true,
          title: "危机干预",
          breadcrumbList: [{
            id: 1,
            name: '危机预警'
          }, {
            id: 2,
            name: '危机干预',
            path: '/home/crisisIntervention'
          }]
        }
      },
      // 干预档案
      {
        path: 'archives',
        name: 'archives',
        component: () => import('../views/dangerWarning/crisisIntervention/Archives.vue'),
        meta: {
          cache: true,
          title: "干预档案",
          breadcrumbList: [{
              id: 1,
              name: '危机预警'
            },
            {
              id: 2,
              name: '危机干预',
              path: '/home/crisisIntervention'
            },
            {
              id: 3,
              name: '干预报告',
              path: '/home/archives'
            }
          ]
        }
      },
      // 订单管理--计划
      {
        path: 'order',
        name: 'order',
        component: () => import('../views/order/plan/Order.vue'),
        meta: {
          cache: true,
          title: "订单列表",
          breadcrumbList: [{
              id: 1,
              name: '订单管理'
            },
            {
              id: 2,
              name: '计划订单列表',
              // path: '/home/crisisIntervention'
            }
          ]
        }
      },
      // 订单管理--预约
      {
        path: 'recordOrder',
        name: 'recordOrder',
        component: () => import('../views/order/record/Index.vue'),
        meta: {
          cache: true,
          title: "订单列表",
          breadcrumbList: [{
              id: 1,
              name: '订单管理'
            },
            {
              id: 2,
              name: '预约订单列表',
              // path: '/home/crisisIntervention'
            }
          ]
        }
      },
      // 档案管理
      {
        path: 'fileManagement',
        name: 'fileManagement',
        component: () => import('../views/healthRecords/fileManagement/FileManagement.vue'),
        meta: {
          cache: true,
          title: "档案管理",
          breadcrumbList: [{
              id: 1,
              name: '心理健康档案'
            },
            {
              id: 2,
              name: '档案管理',
              // path: '/home/crisisIntervention'
            }
          ]
        },
        
      },
      // 健康档案
      {
        path: 'healthRecords',
        name: 'healthRecords',
        component: () => import('../views/healthRecords/fileManagement/HealthRecords.vue'),
        meta: {
          cache: true,
          title: "档案管理",
          breadcrumbList: [{
              id: 1,
              name: '心理健康档案'
            },
            {
              id: 2,
              name: '档案管理',
              path: '/home/fileManagement'
            },
            {
              id: 3,
              name: '健康档案',
              // path: '/home/crisisIntervention'
            }
          ]
        },
        children: [
          {
          path: 'userInfo',
          name: 'userInfo',
          component: () => import('../views/healthRecords/fileManagement/userMessage/Index.vue'),
          meta: {
            cache: true,
            title: "档案管理",
            breadcrumbList: [{
                id: 1,
                name: '心理健康档案'
              },
              {
                id: 2,
                name: '档案管理',
                path: '/home/fileManagement'
              },
              {
                id: 3,
                name: '健康档案',
                // path: '/home/crisisIntervention'
              }
            ]
          },
        },
        {
          path: 'record',
          name: 'record',
          component: () => import('../views/healthRecords/fileManagement/record/Index.vue'),
          meta: {
            cache: true,
            title: "档案管理",
            breadcrumbList: [{
                id: 1,
                name: '心理健康档案'
              },
              {
                id: 2,
                name: '档案管理',
                path: '/home/fileManagement'
              },
              {
                id: 3,
                name: '健康档案',
                // path: '/home/crisisIntervention'
              }
            ]
          },
        }
      ]
      },
      // 个案辅导
      {
        path: 'coach',
        name: 'coach',
        component: () => import('../views/healthRecords/coach/Index.vue'),
        meta: {
          cache: true,
          title: "个案辅导",
          breadcrumbList: [{
              id: 1,
              name: '心理健康档案'
            },
            {
              id: 2,
              name: '个案辅导',
              // path: '/home/crisisIntervention'
            }
          ]
        }
      },
      // 咨询报告
      {
        path: 'consult',
        name: 'consult',
        component: () => import('../views/healthRecords/coach/ConsultDetail.vue'),
        meta: {
          cache: true,
          title: "咨询报告",
          breadcrumbList: [{
              id: 1,
              name: '心理健康档案'
            },
            {
              id: 2,
              name: '个案辅导',
              path: '/home/coach'
            },
            {
              id: 3,
              name: '咨询报告',
              path: '/home/consult'
            }
          ]
        }
      },
      // 个案辅导-干预报告
      {
        path: 'coachArchives',
        name: 'coachArchives',
        component: () => import('../views/healthRecords/coach/Archives.vue'),
        meta: {
          cache: true,
          title: "咨询报告",
          breadcrumbList: [{
              id: 1,
              name: '心理健康档案'
            },
            {
              id: 2,
              name: '个案辅导',
              path: '/home/coach'
            },
            {
              id: 3,
              name: '干预报告',
              path: '/home/coachArchives'
            }
          ]
        }
      },
      // 健康档案 - 答题记录
      {
        path: 'fileDetails1',
        name: 'fileDetails1',
        component: () => import('../views/healthRecords/fileManagement/record/AnswerRecord.vue'),
        meta: {
          cache: true,
          title: "档案管理",
          breadcrumbList: [{
              id: 1,
              name: '心理健康档案'
            }, 
            {
              id: 2,
              name: '档案管理',
              path: '/home/fileManagement'
            },
            {
              id: 3,
              name: '心理健康记录表',
              path: '/home/healthRecords/record'
            },
            {
              id: 4,
              name: '测评记录',
              // path: '/home/healthRecords/record'
            }
          ]
        }
      },
       // 健康档案 - 测评记录
       {
        path: 'fileDetails2',
        name: 'fileDetails2',
        component: () => import('../views/healthRecords/fileManagement/record/ViewReport.vue'),
        meta: {
          cache: true,
          title: "测评报告",
          breadcrumbList: [{
              id: 1,
              name: '心理健康档案'
            }, 
            {
              id: 2,
              name: '档案管理',
              path: '/home/fileManagement'
            },
            {
              id: 3,
              name: '心理健康记录表',
              path: '/home/healthRecords/record'
            },
            {
              id: 4,
              name: '测评报告',
              // path: '/home/healthRecords/record'
            }
          ]
        }
      },
      // 健康档案 - 预警报告
      {
        path: 'fileDetails3',
        name: 'fileDetails3',
        component: () => import('../views/healthRecords/fileManagement/record/WarningReport.vue'),
        meta: {
          cache: true,
          title: "测评报告",
          breadcrumbList: [{
              id: 1,
              name: '心理健康档案'
            }, 
            {
              id: 2,
              name: '档案管理',
              path: '/home/fileManagement'
            },
            {
              id: 3,
              name: '心理健康记录表',
              path: '/home/healthRecords/record'
            },
            {
              id: 4,
              name: '预警报告',
              // path: '/home/healthRecords/record'
            }
          ]
        }
      },
      // 健康档案 - 干预报告
      {
        path: 'fileDetails4',
        name: 'fileDetails4',
        component: () => import('../views/healthRecords/fileManagement/record/Archives.vue'),
        meta: {
          cache: true,
          title: "干预报告",
          breadcrumbList: [{
              id: 1,
              name: '心理健康档案'
            }, 
            {
              id: 2,
              name: '档案管理',
              path: '/home/fileManagement'
            },
            {
              id: 3,
              name: '心理健康记录表',
              path: '/home/healthRecords/record'
            },
            {
              id: 4,
              name: '干预报告',
              // path: '/home/healthRecords/record'
            }
          ]
        }
      },
      // 健康档案 - 咨询报告
      {
        path: 'fileDetails5',
        name: 'fileDetails5',
        component: () => import('../views/healthRecords/fileManagement/record/ConsultDetail.vue'),
        meta: {
          cache: true,
          title: "咨询报告",
          breadcrumbList: [{
              id: 1,
              name: '心理健康档案'
            }, 
            {
              id: 2,
              name: '档案管理',
              path: '/home/fileManagement'
            },
            {
              id: 3,
              name: '心理健康记录表',
              path: '/home/healthRecords/record'
            },
            {
              id: 4,
              name: '咨询报告',
              // path: '/home/healthRecords/record'
            }
          ]
        }
      },
      // 数据分析
      {
        path: 'groupAnalysis',
        name: 'groupAnalysis',
        component: () => import('../views/dataAnalysis/groupAnalysis/Index.vue'),
        meta: {
          cache: true,
          title: "团体分析",
          breadcrumbList: [{
              id: 1,
              name: '数据分析'
            },
            {
              id: 2,
              name: '团体数据分析',
              path: '/home/groupAnalysis'
            },
          ]
        },
      },
      // 心理辅导-设置咨询时间
      {
        path: 'consultingTime',
        name: 'consultingTime',
        component: () => import('../views/appointmentConsultation/consultingTime/Index.vue'),
        meta: {
          cache: true,
          title: "设置咨询时间",
          breadcrumbList: [{
              id: 1,
              name: '心理辅导'
            },
            {
              id: 2,
              name: '设置咨询时间',
              path: '/home/consultingTime'
            },
          ]
        },
      },
      // 心理辅导-预约咨询列表
      {
        path: 'appointmentConsultation',
        name: 'appointmentConsultation',
        component: () => import('../views/appointmentConsultation/appointmentConsultationRecord/Index.vue'),
        meta: {
          cache: true,
          title: "预约咨询列表",
          breadcrumbList: [{
              id: 1,
              name: '心理辅导'
            },
            {
              id: 2,
              name: '预约咨询列表',
              path: '/home/appointmentConsultation'
            },
          ]
        },
      },
      // 心理辅导-预约咨询列表-咨询报告
      {
        path: 'appointmentReport',
        name: 'appointmentReport',
        component: () => import('../views/appointmentConsultation/appointmentConsultationRecord/ConsultDetail.vue'),
        meta: {
          cache: true,
          title: "预约咨询列表",
          breadcrumbList: [{
              id: 1,
              name: '心理辅导'
            },
            {
              id: 2,
              name: '预约咨询列表',
              path: '/home/appointmentConsultation'
            },
            {
              id: 3,
              name: '咨询报告',
              path: '/home/appointmentReport'
            },
          ]
        },
      },
      // 心理辅导-咨询师简历
      {
        path: 'resume',
        name: 'resume',
        component: () => import('../views/appointmentConsultation/resume/Index.vue'),
        meta: {
          cache: true,
          title: "咨询师简历",
          breadcrumbList: [{
              id: 1,
              name: '心理辅导'
            },
            {
              id: 2,
              name: '咨询师简历',
              path: '/home/resume'
            },
          ]
        },
      },
       // 心理辅导-预约须知
       {
        path: 'notice',
        name: 'notice',
        component: () => import('../views/appointmentConsultation/notice/Index.vue'),
        meta: {
          cache: true,
          title: "咨询师简历",
          breadcrumbList: [{
              id: 1,
              name: '心理辅导'
            },
            {
              id: 2,
              name: '预约须知',
              path: '/home/notice'
            },
          ]
        },
      },
      // 个人中心
      {
        path: 'personal',
        name: 'personal',
        component: () => import('../views/personal/Personal.vue'),
        meta: {
          cache: true,
          title: "个人中心",
          breadcrumbList: [{
            id: 1,
            name: '个人中心'
          }]
        }
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router