<template>
  <div class="add">
    <!-- 新增量表 -->
    <div class="header">
      <template>
        <el-menu :default-active="$route.path" text-color="#555"  mode="horizontal" @select="handleSelect" :router="true">
          <el-menu-item :index="item.path" v-for="item in addMenuList" :key="item.id">{{ item.title }}</el-menu-item>
        </el-menu>
        <router-view></router-view>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "first",
      addMenuList: [{ id: 1, title: '量表介绍', name: 'first', path: '/home/addGauge/scaleIntroduction' }, { id: 2, title: '题目编辑', name: 'second',path:'/home/addGauge/titleEditor' }, { id: 3, title: '维度划分', name: 'third',path:'/home/addGauge/dimensionDivision' }, { id: 4, title: '结果解释', name: 'fourth',path:'/home/addGauge/normalSetting' }, { id: 5, title: '预警设置', name: 'fifth',path:'/home/addGauge/alertSetting' }, { id: 6, title: '图表设置', name: 'six',path:'/home/addGauge/ChartSetting' }]
    };
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab.name);
      if (tab.name === 'first') {
        // this.activeName = 'first';
        this.$router.push('/home/addGauge/scaleIntroduction')
      } else if (tab.name === 'second') {
        // this.activeName = 'second'
        this.$router.push('/home/addGauge/titleEditor')
      } else if (tab.name === 'third') {
        // this.activeName = 'second'
        this.$router.push('/home/addGauge/dimensionDivision')
      } else if (tab.name === 'fourth') {
        // this.activeName = 'second'
        this.$router.push('/home/addGauge/normalSetting')
      } else if (tab.name === 'six') {
        // this.activeName = 'second'
        this.$router.push('/home/addGauge/ChartSetting');
      } else {
        // this.activeName = 'second'
        this.$router.push('/home/addGauge/alertSetting')
      }
    },
    handleSelect(key, keyPath) {
      // console.log(this.$route.path)
      // console.log(key, keyPath);
    }
  },
  created() {
    // console.log(this.$route.path)
    if (this.$route.path === '/home/addGauge/scaleIntroduction') {
      this.activeName = 'first'
    } else if (this.$route.path === '/home/addGauge/titleEditor') {
      this.activeName = 'second'
    } else if (this.$route.path === '/home/addGauge/dimensionDivision') {
      this.activeName = 'third'
    } else if (this.$route.path === '/home/addGauge/normalSetting') {
      this.activeName = 'fourth'
    } else {
      this.activeName = 'fifth'
    }
  }
};
</script>

<style lang="scss" scoped>
.add {
  padding: 0 35px;
  padding-top: 10px;
  box-sizing: border-box;
  height: 100%;

  .header {
    ::v-deep.el-tabs__header {
      margin: 0 !important;
    }
    .el-menu{
      position:sticky;
      top: 0;
      z-index: 2;
    }
    .el-menu--horizontal > .el-menu-item{
      padding: 0;
      margin-right: 45px;
    }
  }

  ::v-deep.el-tabs__header.is-top {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
  }

}
</style>