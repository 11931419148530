import Vue from 'vue'
import Vuex from 'vuex'
import gaugeList from './modules/gaugeList'

Vue.use(Vuex)

export default new Vuex.Store({
  // 存储公共数据
  state: {
    // requestApi: 'http://192.168.0.106:8089/api',
    // downLoadUrl: 'http://192.168.0.106:8089'
    projectData: [
      {id: 1, symbol: '>'},
      {id: 2, symbol: '≥'},
      {id: 3, symbol: '<'},
      {id: 4, symbol: '≤'},
      {id: 5, symbol: '='},
      {id: 6, symbol: '范围内个数最多'},
      {id: 7, symbol: '范围内个数最少'},
      {id: 8, symbol: '所有答案中个数最多'},
      {id: 9, symbol: '所有答案中个数最少'},
    ], // 单题计数数组
    requestApi: 'https://cp.xbssc.vip/api',
    downLoadUrl: 'https://cp.xbssc.vip',
    // requestApi: 'http://192.168.0.112:8086/api',
    // downLoadUrl: 'http://192.168.0.112:8086',
    

    comment: '本测试结果只反映受测者当时的状态，内容仅供参考，如有疑问，请拨打心博仕心理咨询热线或咨询专业心理咨询师。'
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    gaugeList
  }
})
