// 组织机构
import request from '@/utils/request'
// 获取组织机构列表
export function getOrganizationList(params) {
  return request({
    url: '/department/list',
    method: 'get',
    params
  });
}
// 获取组织机构详情
export function getOrganizationDetail(params) {
  return request({
    url: '/department',
    method: 'get',
    params
  });
}
// 新增组织机构
export function addOrganization(data) {
  return request({
    url: '/department/save',
    method: 'post',
    data
  });
}
// 修改组织机构
export function updateOrganization(data) {
  return request({
    url: '/department/update',
    method: 'post',
    data
  });
}
// 删除组织机构
export function deleteOrganization(params) {
  return request({
    url: '/department',
    method: 'delete',
    params
  });
}
// 获取未选择的人员
export function getNotMember(params) {
  return request({
    url: '/department/user',
    method: 'get',
    params
  });
}
// 修改人员角色
export function updateMemberRole(data) {
  return request({
    url: '/department/update/userRole',
    method: 'post',
    data
  });
}
// 修改组织机构人员角色
export function updateOrganizationMemberRole(data) {
  return request({
    url: '/department/update/UserRoleAndDept',
    method: 'post',
    data
  });
}