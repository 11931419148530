// 量表列表
import request from '@/utils/request'
// 获取量表列表
export function getGaugeList(params) {
    return request({
      url: '/gauge/page',
      method: 'get',
      params
    });
  }

// 删除量表
export function deleteGauge(params) {
  return request({
    url: `/gauge/delete`,
    method: 'delete',
    params:{id:params}
  });
}