// 维度
import request from '@/utils/request'
// 获取维度列表
export function getDimensionList(params) {
  return request({
    url: '/gauge/dimension/list',
    method: 'get',
    params
  });
}
// 获取维度详情
export function getDimensionDetail(params) {
    return request({
      url: '/gauge/dimension',
      method: 'get',
      params
    });
}
// 新增维度
export function addDimension(data) {
  return request({
    url: '/gauge/save/dimension',
    method: 'post',
    data
  });
}
// 修改维度
export function updateDimension(data) {
  return request({
    url: '/gauge/update/dimension',
    method: 'post',
    data
  });
}
// 删除维度
export function deleteDimension(params) {
  return request({
    url: '/gauge/dimension',
    method: 'delete',
    params
  });
}


// 新增项目数
export function addProject(data) {
  return request({
    url: '/gauge/project',
    method: 'post',
    data
  });
}
// 获取项目数列表
export function getProjectList(gaugeId, params) {
  return request({
    url: `/gauge/${gaugeId}`,
    method: 'get',
    params
  });
}
// 删除项目数
export function deleteProjectList(data) {
  return request({
    url: `/gauge/project`,
    method: 'delete',
    data
  });
}