<template>
  <div class="edit">
    <!-- 题目编辑 -->
    <div class="btn" id="add">
      <div class="block" v-show="dataArr.length > 0">
        <span class="demonstration">第1题</span>
        <el-slider v-model="value1" :format-tooltip="formatTooltip" :max="dataArr.length"></el-slider>
        <span>第{{ dataArr.length }}题</span>
        <el-button type="primary" size="small" @click="toTitle">跳转</el-button>
      </div>
      <!-- <div class="blockNone" v-else></div> -->
      <div class="addBtn" ref="add">
        <!-- <span @click="toAdd">跳转</span> -->
        <el-button type="primary" size="small" @click="AddTitle">新增题目</el-button>
      </div>
    </div>
    <div class="titleContent" v-loading="problemLoading" v-for="item in dataArr" :key="item.id">
      <template>
        <div class="item" :key="item.problem.id" :id="'num' + item.problem.orderNum">
          <div class="title">
            <div class="left">
              <span>第
                <el-input v-model="item.problem.orderNum" placeholder="请输入题号" size="mini" @blur="submit(item)">
                </el-input>
                题
              </span>
              <el-select v-model="item.problem.type" placeholder="请选择" size="mini" @change="submit(item)">
                <el-option label="单选" :value="1"></el-option>
                <el-option label="多选" :value="2"></el-option>
                <el-option label="图片单选" :value="3"></el-option>
                <el-option label="图片多选" :value="4"></el-option>
              </el-select>
              <el-checkbox v-model="item.problem.isAnswer" @change="submit(item)">必选题</el-checkbox>
            </div>
            <div class="right">
              <!-- <el-button type="text" @click="submit(item)">保存</el-button> -->
              <el-button type="text" @click="copyItem(item)">复制</el-button>
              <el-button type="text" @click="deleteTitle(item)">删除</el-button>
            </div>
          </div>
          <div class="topic">
            <div v-show="item.problem.type === 3 || item.problem.type === 4" class="img">
              <el-upload class="avatar-uploader" :action="$store.state.requestApi + '/gauge/upload'" :data="{
                id: item.problem.id,
                type: 1
              }" :headers="{ token }" :show-file-list="false" :on-change="updateImg" :on-success="uploadImg">
                <img v-if="item.problem.url" :src="item.problem.url" class="avatar">
                <span v-else class="uploadAdd">
                  <i class="el-icon-upload2"></i>
                  上传</span>
              </el-upload>
              <div v-if="item.problem.url" class="imgBtn">
                <el-popconfirm title="确认移除该图片？" @confirm="deleteImg(1, item)">
                  <el-button type="text" slot="reference">移除</el-button>
                </el-popconfirm>
                <el-button type="text" @click="toSee(item.problem.url)">预览</el-button>
              </div>
            </div>
            <el-input v-model="item.problem.name" placeholder="请输入问题" size="small" class="answer" @blur="submit(item)">
            </el-input>
          </div>
          <div class="answerList" v-for="(ele, index) in item.answerList" :key="ele.id">
            <el-input v-model="ele.orderNum" placeholder="答案序号" size="small" class="num" @blur="submit(item)">
            </el-input>
            <div class="img" v-show="item.problem.type === 3 || item.problem.type === 4">
              <el-upload class="avatar-uploader" :action="$store.state.requestApi + '/gauge/upload'" :data="{
                id: ele.id,
                type: 2
              }" :headers="{ token }" :show-file-list="false" :on-change="updateImg" :on-success="uploadImg">
                <img v-if="ele.url" :src="ele.url" class="avatar">
                <span v-else class="uploadAdd">
                  <i class="el-icon-upload2"></i>
                  上传</span>
              </el-upload>
              <div v-show="ele.url" class="imgBtn">
                <el-popconfirm title="确认移除该图片？" @confirm="deleteImg(2, item, ele.id)">
                  <el-button type="text" slot="reference">移除</el-button>
                </el-popconfirm>
                <el-button type="text" @click="toSee(ele.url)">预览</el-button>
              </div>
            </div>
            <el-input v-model="ele.name" placeholder="请输入选项答案" size="small" @blur="submit(item)" class="answer">
            </el-input>
            <el-input v-model="ele.score" placeholder="请输入分值" size="small" class="score" @blur="submit(item)">
            </el-input>
            <div class="answerBtn">
              <!-- <el-button type="text" size="small">删除</el-button> -->
              <el-popconfirm title="确认删除该选项吗？" @confirm="deleteAnswer(ele, item.answerList)">
                <el-button type="text" slot="reference">删除</el-button>
              </el-popconfirm>
              <el-button type="text" v-if="index === item.answerList.length - 1" @click="addTitleItem(item)"
                style="margin-left:10px">新增
              </el-button>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="toTop" @click="toTop" v-if="dataArr.length > 4">
      <i class="el-icon-top"></i>
      <span class="text">回到顶部</span>

    </div>
    <div id="bottom"></div>
    <SeeImgVue ref="child"></SeeImgVue>
  </div>
</template>

<script>
import { getTitleList, addTitle, deleteTitle, deleteTitleAnswer, updateTitleOrderNum, updateAnswerOrderNum } from '@/api/gauge/titleEdit.js'
import SeeImgVue from './SeeImg.vue';
export default {
  data() {
    return {
      problemLoading: false,
      value1: 0,
      token: '',
      value: '', // 题型
      options: [],
      imageUrl: '',
      dataList: [],
      addItem: {
        answerList: [
          {
            name: '',
            orderNum: 1,
            score: null,
            url: ''
          },
          {
            name: '',
            orderNum: 2,
            score: null,
            url: ''
          },
          {
            name: '',
            orderNum: 3,
            score: null,
            url: ''
          },
          {
            name: '',
            orderNum: 4,
            score: null,
            url: ''
          }
        ],
        problem: {
          gaugeId: 0,
          isAnswer: true,
          name: '',
          type: 1,
          url: ''
        }
      },
      TitleItem: {
        name: '',
        orderNum: 5,
        score: null,
        url: ''
      },
      dialogImageUrl: '', // 预览文件路径
      dialogVisible: false, // 预览文件模态框状态
      disabled: false, // 删除
      status: 0 // 给一个状态判断答案是否为空
    }
  },
  methods: {
    // 跳转到对应的题
    toTitle() {
      if (this.value1 > 0) {
        // val想要跳往的dom的id
        // this.$refs('num7').scrollIntoView(true);
        const dom = document.getElementById(`num${this.value1}`);
        console.log(dom);
        if (dom) {
          this.$nextTick(() => {
            dom.scrollIntoView({ behavior: "smooth", block: "end" });
          })
        }
      }
    },
    //回到顶部
    toTop() {
      // console.log(111)
      document.querySelector('#add').scrollIntoView({ behavior: "smooth", block: "end" })
    },
    formatTooltip(val) {
      // console.log(val)
      return val;
    },
    // 获取当前量表下的所有已提交的题目
    async getList() {
      const res = await getTitleList({ id: sessionStorage.getItem('gaugeId') })
      console.log(res);
      // this.dataList = res.data;
      if (res.code === 200) {
        this.$forceUpdate();
        this.dataList = res.data;
        // this.list = res.data;
        // this.dataList= JSON.parse(JSON.stringify(this.list))
        console.log(this.dataList);
      }
    },
    // 新增题目
    async AddTitle() {
      console.log(sessionStorage.getItem('gaugeId'))
      // 新增
      if (sessionStorage.getItem('gaugeId') === 'null') {
        // console.log(1111)
        this.$message({
          showClose: true,
          message: '请先确认量表介绍',
          type: 'info'
        });
      } else {
        this.addItem.problem.gaugeId = sessionStorage.getItem('gaugeId');
        // this.addItem.problem.orderNum = this.dataList.length + 1
        console.log(this.addItem);
        const res = await addTitle(JSON.stringify(this.addItem));
        console.log(res);
        if (res.code === 200) {
          this.$message({
            showClose: true,
            message: '新增成功',
            type: 'success'
          });
          setTimeout(() => {
            document.querySelector('#bottom').scrollIntoView()
          }, 1000);
          this.getList();
          // console.log(document.getElementById(`num${this.dataList.length}`))


        } else {
          this.$message({
            showClose: true,
            message: '操作过快，请稍后重试！',
            type: 'error'
          });
        }
        this.getList();

      }
    },
    // 保存修改
    async submit(value) {
      this.problemLoading = true;
      let info = JSON.parse(JSON.stringify(value));
      // 修改问题
      const res = await addTitle(JSON.stringify(info));
      console.log(res);
      if (res.code === 200) {
        // this.$message({
        //   showClose: true,
        //   message: '保存成功',
        //   type: 'success'
        // });
         this.problemLoading = false;
        this.getList();
      } else {
        this.$message({
          showClose: true,
          message: '保存失败！' + res.msg,
          type: 'error'
        });
        this.problemLoading = false;
        this.getList();
      }

    },
    // 删除题目
    async deleteTitle(value) {
      this.$confirm('确认删除该题?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await deleteTitle({ id: value.problem.id })
        console.log(res)
        if (res.code === 200) {
          this.$message({
            showClose: true,
            type: 'success',
            message: '删除成功!'
          });
          // console.log(111)
          this.getList();
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '删除失败!' + res.msg
          });
        }
      }).catch(() => {
        this.$message({
          showClose: true,
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    // 删除答案选项
    async deleteAnswer(val, val2) {
      console.log(val)
      if (val2.length > 1) {
        const res = await deleteTitleAnswer({ id: val.id })
        console.log(res)
        if (res.code === 200) {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          });
          this.getList()
        } else {
          this.$message({
            showClose: true,
            message: '删除失败',
            type: 'error'
          });
        }
      } else {
        this.$message({
          showClose: true,
          message: '选项不能为空',
          type: 'info'
        });
      }

    },
    // 新增答案选项
    async addTitleItem(val) {
      this.TitleItem.orderNum = val.answerList.length + 1;
      val.answerList.push(this.TitleItem)
      console.log(val)
      const res = await addTitle(JSON.stringify(val));
      console.log(res);
      if (res.code === 200) {
        this.$message({
          showClose: true,
          message: '新增成功',
          type: 'success'
        });
        this.getList();
      } else {
        this.$message({
          showClose: true,
          message: '新增失败' + res.msg,
          type: 'error'
        });
      }
      // this.submit(val);
    },
    // 文件上传成功触发的钩子函数
    uploadImg(res) {
      console.log(res);
      if (res.code === 200) {
        this.$message({
          showClose: true,
          message: '图片上传成功'
        });
        this.getList()
      } else {
        this.$message({
          showClose: true,
          message: '图片上传失败' + res.msg
        });
      }
    },
    // 文件上传触发的钩子函数
    updateImg(res, res2) {
      console.log(res, res2);
      // if (res.response.code === 200) {
      //   this.getList()
      // } else {
      //    this.$message({
      //     showClose: true,
      //     message: '图片上传失败'+ res.response.msg
      //   });
      // }
    },
    // 点击预览
    toSee(val) {
      // window.location.href= val;
      this.$refs.child.open(val);
    },
    // 移除图片
    deleteImg(type, val1, val2) {
      console.log(val1)
      console.log(val2)
      if (type === 1) {
        // 删除题目图片
        val1.problem.url = ''
      } else {
        val1.answerList.forEach(item => {
          if (item.id === val2) {
            item.url = ''
          }
        })
      }
      this.submit(val1);
    },
    // 修改问题序号 （未用）
    async updateOrderNum(val) {
      // console.log(val)
      if (val.orderNum !== '') {
        const res = await updateTitleOrderNum({ id: val.id, orderNum: val.orderNum, gaugeId: val.gaugeId })
        console.log(res);
        if (res.code === 200) {
          this.$message({
            showClose: true,
            message: '修改成功',
            type: 'success'
          });
          this.getList();
        } else {
          this.$message({
            showClose: true,
            message: '修改失败！' + res.msg,
            type: 'error'
          });
          this.getList();
        }
      } else {
        this.$message({
          showClose: true,
          message: '序号不能为空',
          type: 'info'
        });
        this.getList();
      }

    },
    // 修改答案序号 （未用）
    async updateAnswerOrderNum(val1, val2) {
      console.log(val1);
      console.log(val2);
      if (val2.orderNum !== '') {
        const res = await updateAnswerOrderNum({ id: val2.id, orderNum: val2.orderNum, problemId: val1.id })
        if (res.code === 200) {
          this.$message({
            showClose: true,
            message: '修改成功',
            type: 'success'
          });
          this.getList();
        } else {
          this.$message({
            showClose: true,
            message: '修改失败！' + res.msg,
            type: 'error'
          });
          this.getList();
        }
      } else {
        this.$message({
          showClose: true,
          message: '序号不能为空',
          type: 'info'
        });
        this.getList();
      }

    },
    // 复制
    async copyItem(val) {
      // console.log(val)
      let addCopy = JSON.parse(JSON.stringify(val));
      // addCopy.problem.orderNum = this.dataList.length + 1;
      // console.log(addCopy);
      delete addCopy.problem.id;
      delete addCopy.problem.createTime;
      delete addCopy.problem.orderNum;
      addCopy.answerList.forEach(item => {
        delete item.id;
        delete item.createTime;
      })
      console.log(addCopy);
      const res = await addTitle(JSON.stringify(addCopy));
      console.log(res);
      if (res.code === 200) {
        this.$message({
          showClose: true,
          message: '复制成功',
          type: 'success'
        });
        this.getList();
      } else {
        this.$message({
          showClose: true,
          message: '操作过快，请稍后重试！',
          type: 'error'
        });
        this.getList();
      }
      // this.getList();
    }
  },
  created() {
    this.getList();
    this.token = sessionStorage.getItem('token');
    console.log(this.token);
  },
  computed: {
    dataArr() {
      return this.dataList
    }
  },
  // watch: {
  //   dataList: function (newvalue, oldvalue2) {
  //     // 通过value参数(参数名可以自己命名)接受侦听数据的最新值
  //     console.log(newvalue)
  //     console.log(oldvalue2)
  //   },
  //   deep: true
  // },
  components: { SeeImgVue }
  };
</script>

<style lang="scss" scoped>
.edit {
  background: white;

  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  ::v-deep input[type='number'] {
    -moz-appearance: textfield !important;
  }

  .btn {
    display: flex;
    align-items: center;
    // position: absolute;
    // position: sticky;
    // top: 0;
    margin-top: 20px;
    margin-bottom: 10px;

    // .blockNone{
    //   width: 400px;
    // }
    .block {
      //   width: 300px;
      display: flex;
      align-items: center;
      z-index: 1;
      margin-right: 50px;

      // height: 60px;
      span {
        font-size: 14px;
      }

      .el-slider {
        width: 600px;
        max-width: 700px;
      }

      span {
        display: inline-block;
        margin: 0 20px;

        &:nth-child(1) {
          margin-left: 0px;
        }
      }
    }


  }

  .titleContent {
    .item {
      .answer {
        flex: 1;
      }

      margin-top: 20px;
      // .img{
      //     width: 100px;
      //     // border: 1px solid #DCDFE6;
      //     border-right: 1px solid #DCDFE6;
      //     // height: 32px;
      //     box-sizing: border-box;
      //     display: flex;
      //     img{
      //       height: 32px;
      //       width: 32px;
      //     }
      //     span{
      //         font-size: 12px;
      //       }
      //     .imgBtn{
      //       display: flex;
      //       height: 32px;

      //     }
      //   }
      .img {
        height: 32px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border-right: 1px solid #DCDFE6;

        .uploadAdd {
          font-size: 12px;
          color: #555;
          // i{
          //   font-size: 16px;
          // }

        }

        .uploadAdd:hover {
          color: #209deb;
        }

        img {
          height: 32px;
          width: 32px;
        }

        .imgBtn {
          height: 32px;
          // display: block;
          margin-left: 5px;

          .el-button {
            font-size: 12px;
            margin: 0px;

          }
        }
      }

      .title {
        padding: 0 20px;
        height: 40px;
        background: #eee;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 14px;
        }

        .left {
          .el-select {
            width: 100px;
            margin: 0 30px;
          }

          .el-input {
            width: 50px;
          }
        }
      }

      .topic {
        border: 1px solid #DCDFE6;
        display: flex;
        align-items: center;

        ::v-deep.el-input__inner {
          border: 0;

          // height: 35px !important;
        }

        // margin-bottom: 5px;
        span {
          font-size: 12px;
        }

      }

      .answerList {
        display: flex;
        align-items: center;
        border: 1px solid #DCDFE6;
        border-top: 0;

        .num,
        .score {
          width: 100px;
          // border: none;
        }

        ::v-deep.el-input__inner {
          border: 0;
          border-radius: 0;
          border-right: 1px solid #DCDFE6;
          // height: 35px !important;
        }



        .answerBtn {
          height: 32px;
          width: 150px;
          // border: 1px solid #DCDFE6;
          box-sizing: border-box;
          border-radius: 5px;

          .el-button {
            margin: 0 10px;
          }
        }

      }
    }
  }

  .toTop {
    height: 30px;
    width: 30px;
    background-color: var(--custom-color);
    position: absolute;
    right: 2%;
    bottom: 20%;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      display: block;
      color: white;

    }

    span {
      font-size: 12px;
      color: white;
      display: none;
      padding: 2px;
    }
  }

  .toTop:hover {
    span {
      display: block;
    }

    i {
      display: none;
    }
  }
}
</style>