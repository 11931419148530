<template>
    <!-- 编辑组织机构 -->
    <div class="edit">
        <el-drawer title="管理人员" :show-close="false" :visible.sync="dialog" direction="rtl" size="50%"
            :before-close="handleClose" custom-class="demo-drawer" ref="drawer">
            <div class="demo-drawer__content">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" size="small">
                    <el-form-item prop="name">
                        <span>组织机构名称</span>
                        <br />
                        <el-input v-model="ruleForm.name" style="width:45%"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span>父级组织机构</span>
                        <br />
                        <!-- <div></div> -->
                        <el-cascader :options="options"
                            :props="{ value: 'id', label: 'name', checkStrictly: true, emitPath: false }"
                            v-model="ruleForm.organization" style="width:45%"></el-cascader>
                    </el-form-item>
                    <el-form-item>
                        <span>删除该组织机构用户所属</span>
                        <br />
                        <el-cascader :options="options1" :show-all-levels="false"
                            :props="{ value: 'id', label: 'name', emitPath: false }" v-model="ruleForm.deleteId"
                            style="width:45%"></el-cascader>
                        <!-- <el-switch v-model="ruleForm.isRegister" active-color="#999" 
                            inactive-color="#cc0022" :active-value="1" :inactive-value="0">
                        </el-switch> -->
                    </el-form-item>
                    <el-form-item>
                        <span>是否允许注册</span>
                        <br />
                        <el-switch v-model="ruleForm.isRegister" :active-value="1" :inactive-value="0">
                        </el-switch>
                    </el-form-item>
                    <el-form-item v-show="ruleForm.isRegister === 1">
                        <span>注册用户所属</span>
                        <br />
                        <!-- checkStrictly: true, emitPath: false -->
                        <el-cascader :options="ruleForm.children" :show-all-levels="false"
                            :props="{ value: 'id', label: 'name', emitPath: false }"
                            v-model="ruleForm.belongingId" style="width:45%"></el-cascader>
                        <!-- <el-switch v-model="ruleForm.isRegister" active-color="#999" 
                            inactive-color="#cc0022" :active-value="1" :inactive-value="0">
                        </el-switch> -->
                    </el-form-item>
                    <el-form-item v-show="ruleForm.isRegister === 1">
                        <span>注册地址</span>
                        <br />
                        <span>
                            <template>{{ this.$store.state.downLoadUrl }}</template>?isRegister={{ ruleForm.isRegister }}?departmentId={{ ruleForm.belongingId }}</span>
                        <!-- <span>http://xbssc.zhihanyixing.com?isRegister={{ruleForm.isRegister}}?departmentId={{ruleForm.belongingId}}</span> -->
                    </el-form-item>
                    <el-form-item v-show="ruleForm.isRegister === 0">
                        <span>注册地址</span>
                        <br />
                        <span><template>{{ this.$store.state.downLoadUrl }}</template>?isRegister={{ ruleForm.isRegister }}</span>
                    </el-form-item>
                    <el-form-item>
                        <span>地址</span>
                        <br />
                        <el-input v-model="ruleForm.address" placeholder="请输入地址"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span>管理员姓名</span>
                        <br />
                        <el-input v-model="ruleForm.adminName" placeholder="请输入管理员姓名"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span>管理员电话</span>
                        <br />
                        <el-input v-model="ruleForm.adminPhone" placeholder="请输入管理员电话"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span>管理员邮箱</span>
                        <br />
                        <el-input v-model="ruleForm.adminEmail" placeholder="请输入邮箱"></el-input>
                    </el-form-item>
                </el-form>
                <template>
                    <div class="role" v-for="item in organizationDetail.departmentRoleVOList" :key="item.id">
                        <div class="name">
                            <span>{{ item.roleName }}</span><span>({{ item.count }})</span>
                            <el-button type="danger" plain size="mini" @click="editMember(item)"
                                class="editBtn">编辑</el-button>
                        </div>
                        <div class="roleList">
                            <span v-for=" ele in item.userList" :key="ele.id">{{ ele.nickname }}</span>
                        </div>
                    </div>
                    <!-- <div >
                        <el-button type="danger" plain size="mini" @click="editMember(item)" class="editBtn">编辑</el-button>
                    </div> -->
                </template>
            </div>
            <div class="demo-drawer__footer">
                <el-button size="small" @click="cancel">取 消</el-button>
                <el-button type="primary" size="small" @click="submitForm('ruleForm')"> 确定</el-button>
            </div>
            <AddMemberVue ref="addMember" @getDetail="getDetail"></AddMemberVue>
        </el-drawer>

    </div>
</template>

<script>
import { getOrganizationList } from '@/api/system/organization'
import { getOrganizationDetail, addOrganization, updateOrganization } from '@/api/system/organization'
import AddMemberVue from './AddMember.vue';
export default {
    data() {
        return {
            dialog: false,
            loading: false,
            options: null,
            options1: null,
            ruleForm: {
                name: '',
                organization: null,
                address: null,
                adminName: null,
                adminPhone: null,
                adminEmail: null,
                children: [],
                belongingId: null,
                deleteId: null
            },
            rules: {
                name: [{ required: true, message: '请输入组织机构名称', trigger: 'blur' },
                { max: 15, message: '长度在 15 个字符以内', trigger: 'change' }],
                organization: [
                    { required: true, message: '请选择父级组织机构', trigger: 'blur' }
                ]
            },
            timer: null,
            organizationId: null, // 组织机构的id
            organizationDetail: {} // 当前组织机构的详情
        }

    },
    methods: {
        //打开当前抽屉
        openEdit(val) {
            this.organizationId = val;
            this.ruleForm.organization = null;
            this.getDetail();
            this.getAllList();
            this.dialog = true;
            console.log(this.ruleForm)
        },

        handleClose(done) {
            done();
            this.$emit('getList');
            // this.$confirm('确认关闭？')
            //     .then(_ => {
            //         done();
            //     })
            //     .catch(_ => { });
        },
        // 获取组织机构列表
        async getAllList() {
            const res = await getOrganizationList()
            console.log(res);
            if (res.code === 200) {
                this.options = res.data;
                this.options1 = res.data;
                if ((JSON.parse(sessionStorage.getItem('userInfo'))).roleId === 0) {
                    this.options = [{ id: 0, name: '无(第一级)' }, ...this.options];
                }
                // this.options = [{ id: 0, name: '无(第一级)' }, ...this.options];
                // this.options.push({ id: 0, name: '无(第一级)' })
                // this.deleteNullChildren(this.options)
            }
        },
        // 使用递归取出children为空的数据
        deleteNullChildren(val) {
            console.log(val);
            if (val) {
                val.forEach(item => {
                    if (item.children) {
                        if (item.children.length < 1) {
                            delete item.children;
                        }
                    }
                    this.deleteNullChildren(item.children)
                });
            }
        },
        // 点击提交
        // 点击确认
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    // console.log(this.ruleForm.organization[0]);
                    // if (this.ruleForm.organization !== null) {
                    //     this.ruleForm.organization = this.ruleForm.organization[0]       
                    // }
                    const res = await updateOrganization({ id: this.organizationId, name: this.ruleForm.name, parentId: this.ruleForm.organization, address: this.ruleForm.address, adminName: this.ruleForm.adminName, adminPhone: this.ruleForm.adminPhone, adminEmail: this.ruleForm.adminEmail, isRegister: this.ruleForm.isRegister, belongingId: this.ruleForm.belongingId, deleteId: this.ruleForm.deleteId });
                    console.log(res);
                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            message: '保存成功',
                            type: 'success'
                        });
                        this.dialog = false;
                        this.$emit('getList');
                    } else {
                        this.$message({
                            showClose: true,
                            message: '保存失败' + res.msg,
                            type: 'error'
                        });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 获取当前组织机构详情
        async getDetail() {
            const res = await getOrganizationDetail({ id: this.organizationId })
            console.log(res);
            if (res.code === 200) {
                this.organizationDetail = res.data;
                this.ruleForm.name = res.data.name;
                this.ruleForm.organization = res.data.parentId;
                this.ruleForm.children = res.data.children;
                this.ruleForm.address = res.data.address;
                this.ruleForm.adminName = res.data.adminName;
                this.ruleForm.adminPhone = res.data.adminPhone;
                this.ruleForm.adminEmail = res.data.adminEmail;
                this.ruleForm.isRegister = res.data.isRegister;
                this.ruleForm.belongingId = res.data.belongingId;
                this.ruleForm.deleteId = res.data.deleteId;

            }
        },
        // 点击编辑人员
        editMember(val) {
            console.log(val);
            // console.log(this.$refs.addMember)
            //  this.$refs.editChild.openEdit(val.id);
            this.$refs.addMember.openMember(val, this.organizationId);
        },
        cancel() {
            this.dialog = false;
            this.$emit('getList');
        }
    },
    components: { AddMemberVue }
}
</script>

<style lang="scss" scoped>
.edit {
    position: relative;

    .demo-drawer__content {
        height: 80%;
        overflow-y: scroll;

        // padding: 0 40px;

        .name {
            span {
                font-size: 14px;
            }

            .el-button {
                margin-left: 20px;
            }
        }

        .role {
            margin-top: 40px;
        }

        .roleList {
            span {
                display: inline-block;
                margin: 0 5px;
            }
        }
    }

    // .box{
    //     height: 800px;
    // }
    .demo-drawer__footer {
        .el-button {
            margin: 0 30px;
        }
    }
}
</style>