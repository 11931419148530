import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './element-variables.scss'
// 动态生成二维码
import QRCode from 'qrcodejs2'
// // 导入flexible
// import 'amfe-flexible'
// // import 'px2rem'
// import './utils/flexible'
// import './utils/px2rem'
import print from './assets/vue-print-nb'

import utils from '@/utils/toType'


//全局引入echarts
import * as echarts from 'echarts';

import axios from 'axios'
//需要挂载到Vue原型上
Vue.prototype.$echarts = echarts;
Vue.prototype.$utils = utils;
Vue.config.productionTip = false
Vue.prototype.$http = axios;
Vue.use(print)
Vue.prototype.$print = print;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset-UTF-8';
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
