<template>
    <div class="img">
        <el-dialog :visible.sync="centerDialogVisible" width="100%" center>
            <div class="content">
                <img :src="imgUrl" alt="" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            centerDialogVisible: false,
            imgUrl: ''
        };
    },
    methods: {
        open(val) {
            this.imgUrl = val
            this.centerDialogVisible = true;
        }
    }

}
</script>

<style scoped lang="scss">
.img {

    // background-color: transparent; 
    // background-color: rgba($color: #ffffff, $alpha: 0.1); 
    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: rgba($color: #ffffff, $alpha: 0.1); 
    }

    ::v-deep.el-dialog__header,
    ::v-deep.el-dialog__body {
        margin: 0 !important;
        padding: 0 !important;
    }

    ::v-deep.el-dialog {
        // margin-top: 0px !important;
        // margin-bottom: 0px !important;
        // height: 100% !important;
        background: transparent;

        .el-dialog__headerbtn {
            font-size: 26px;

        }

        .el-icon-close {
            color: aliceblue;
        }
    }

}
</style>