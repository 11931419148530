<template>
  <div class="addOrganization">
    <!-- 新增组织机构 -->
    <el-dialog title="新增组织机构" :visible.sync="dialogVisible" width="30%"  :show-close="false" >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" size="small">
      <el-form-item prop="name">
          <span>组织机构名称</span>
          <br />
          <el-input v-model="ruleForm.name" placeholder="仅限输入15个字符"></el-input>
        </el-form-item>
        <el-form-item prop="organization">
          <span>父级组织机构</span>
          <br />
          <!-- <div></div> -->
          <el-cascader :options="options" clearable :props="{ value: 'id', label: 'name', checkStrictly: true }" @change="getCheckedNodes"
            v-model="ruleForm.organization"  style="width:100%"></el-cascader>
        </el-form-item>
        <el-form-item>
          <span>地址</span>
          <br />
          <el-input v-model="ruleForm.address" placeholder="请输入地址"></el-input>
        </el-form-item>
        <el-form-item>
          <span>管理员姓名</span>
          <br />
          <el-input v-model="ruleForm.adminName" placeholder="请输入管理员姓名"></el-input>
        </el-form-item>
        <el-form-item>
          <span>管理员电话</span>
          <br />
          <el-input v-model="ruleForm.adminPhone" type="number" placeholder="请输入管理员电话"></el-input>
        </el-form-item>
        <el-form-item>
          <span>管理员邮箱</span>
          <br />
          <el-input v-model="ruleForm.adminEmail" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" size="small">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getOrganizationList, addOrganization } from '@/api/system/organization'
export default {
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        name: '',
        organization: null,
        address: null,
        adminName: null,
        adminPhone: null,
        adminEmail: null
      },
      SelectLevel: null,
      level: 1,
      rules: {
        name: [
          { required: true, message: '请输入组织机构名称', trigger: 'blur' },
          { max: 15, message: '长度在 15 个字符以内', trigger: 'change' }
        ],
        organization: [
          { required: true, message: '请选择父级组织机构', trigger: 'blur' }
        ]
      },
      options: []
    };
  },
  methods: {
    // 控制当前模态框
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    // 打开当前模态框
    openAdd() {
      this.dialogVisible = true;
      this.ruleForm = {
        name: '',
        organization: null
      };
      this.getAllList();
    },
    // 获取组织机构列表
    async getAllList() {
      const res = await getOrganizationList()
      console.log(res);
      if (res.code === 200) {
        this.options = res.data;
        let parent = 0;
        let FirstLevel = 0;
        if(this.options.length > 0) {
          parent = this.options[0].parentId
          FirstLevel = this.options[0].level
        } else {
          parent = 0;
          FirstLevel = 0;
        }
        // console.log()
        // if(JSON.parse(sessionStorage.getItem('userInfo')).roleId)
        // console.log(parent)
        // console.log(FirstLevel)
        if((JSON.parse(sessionStorage.getItem('userInfo'))).roleId === 0) {
          this.options = [{ id: 0, name: '无(第一级)', level: FirstLevel - 1, parentId: parent},...this.options];
        }
        
        // this.options.push({ id: 0, name: '无(第一级)' })
        // this.deleteNullChildren(this.options)
      }
    },
    // 使用递归取出children为空的数据
    deleteNullChildren(val) {
      console.log(val);
      if (val) {
        val.forEach(item => {
          if (item.children) {
            if (item.children.length < 1) {
              delete item.children;
            }
          }
          this.deleteNullChildren(item.children)
        });
      }
    },
    // 提交
    submitForm(formName) {
      console.log(this.SelectLevel)
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // console.log(this.ruleForm);
          // if (this.ruleForm.organization[this.ruleForm.organization.length - 1] !== 0) {
          //   this.level =this.ruleForm.organization.length + 1;
          // } else {
          //   this.level = 1;
          // }
          let fatherId = null;
          if(this.SelectLevel.id === 0) {
            fatherId = this.SelectLevel.parentId
          } else {
            fatherId = this.SelectLevel.id;
          }
          this.level = this.SelectLevel.level + 1;
          let add = { name: this.ruleForm.name, parentId: fatherId, level: this.level, address: this.ruleForm.address, adminName:this.ruleForm.adminName, adminPhone: this.ruleForm.adminPhone, adminEmail:this.ruleForm.adminEmail};
          console.log(add);
          const res = await addOrganization({ ...add});
          console.log(res);
          if (res.code === 200) {
            this.$message({
              showClose: true,
              message: '新增成功',
              type: 'success'
            });
            this.dialogVisible = false;
            this.$emit('getList');
          } else {
            this.$message({
              showClose: true,
              message: '新增失败' + res.msg,
              type: 'error'
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // // 获取用户选择组织机构
    // handleChange(val) {
    //   console.log(val);
    //   this.getCheckedNodes();

    // },
    getCheckedNodes(val) {
      console.log(val);
      // console.log(this.options)
      this.getSelectLevel(this.options, val)
      // console.log(this.SelectLevel)
    },
    getSelectLevel(val,val2) {
      // console.log(val)
      // console.log(val2)
      val.forEach(item => {
        // console.log(item.children)
        if(item.id === val2[val2.length - 1]) {
          this.SelectLevel = item
        } else {
          if(item.children) {
            this.getSelectLevel(item.children,val2)
          }
          
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
</style>