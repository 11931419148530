// 量表介绍
import request from '@/utils/request'
// 获取量表列表
export function getGaugeType() {
  return request({
    url: '/gaugetype/list',
    method: 'get'
  });
}
// 新增量表类别
export function addGaugeType(data) {
  return request({
    url: '/gaugetype/save',
    method: 'post',
    data
  });
}
// 删除量表类别
export function deleteGaugeType(params) {
  return request({
    url: '/gaugetype',
    method: 'delete',
    params
  });
}
// 修改量表类别
export function updateGaugeType(data) {
  return request({
    url: '/gaugetype/update',
    method: 'post',
    data
  });
}
// 新增量表介绍
export function addGaugeIntroduction(data) {
  return request({
    url: '/gauge',
    method: 'post',
    data
  });
}
// 获取量表详情
export function getDetail(params) {
  return request({
    url: '/gauge',
    method: 'get',
    params
  });
}